<template>
  <div class="space-y-4">
    <div
      v-for="item in searchResults"
      :key="item.id"
      class="w-full p-1 rounded-2xl flex items-center space-x-4 hover:bg-[#1B1B1B] cursor-pointer"
      @click="router.push(`/programs/${item.id}`)"
    >
      <div class="shrink-0 size-[52px] rounded-2xl flex justify-center items-center">
        <img class="size-full object-cover" :src="item.iconUrl">
      </div>
      <div class="flex-1 flex-col flex justify-start items-start space-y-1 truncate">
        <div class="w-full text-white text-base font-semibold leading-none truncate">{{ item.name }}</div>
        <div class="w-full text-[#A7A7A7] text-sm font-medium leading-tight truncate">{{ item.description }}</div>
      </div>
    </div>

    <vue-eternal-loading v-model:is-initial="isInitial" :load="load">
      <template #loading>
        <div class="space-y-4">
          <div v-for="i in PER_PAGE" :key="i" class="w-full p-1 rounded-2xl flex items-center space-x-4 animate-pulse">
            <div class="shrink-0 size-[52px] rounded-2xl bg-[#1B1B1B]" />
            <div class="flex-1 flex-col flex justify-start items-start space-y-1 truncate">
              <div class="w-2/5 h-4 bg-[#1B1B1B] rounded" />
              <div class="w-3/4 h-4 bg-[#1B1B1B] rounded" />
            </div>
          </div>
        </div>
      </template>
    </vue-eternal-loading>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { VueEternalLoading } from '@ts-pro/vue-eternal-loading';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';
import { useRouter } from 'vue-router';

const props = defineProps({
  searchString: {
    type: String,
    default: null
  },
  section: {
    type: String,
    default: null
  }
});

watch(() => props.section, reset);
watch(() => props.searchString, reset);

const PER_PAGE = 10;

const router = useRouter();

const isInitial = ref(true);
const page = ref(0);

const searchResults = ref([]);

const url = computed(() => {
  const data = {
    limit: PER_PAGE,
    offset: page.value * PER_PAGE
  };

  if (props.searchString) {
    data.search = props.searchString;
  }

  if (props.section === 'free' || props.section === 'paid') {
    data.licenseType = props.section;
  }

  data.order = props.section;

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/program/?${searchParams}`;
});

async function load({ loaded }) {
  const { data } = await useFetch(url.value).get().json();

  page.value++;
  searchResults.value.push(...data.value);
  loaded(data.value.length, data.value.length);
}

function reset() {
  page.value = 0;
  searchResults.value = [];
  isInitial.value = true;
}
</script>
