<template>
  <div
    ref="sectionContainer"
    class="h-screen flex relative bg-[#FFFFFF] -mt-px gap-24
    xs:px-[10px] xs:py-0 sm:px-[23px] sm:py-0 md:px-[160px] md:py-0 lg:px-[116px] lg:py-[176px] x:px-[172px] x:py-[107px] 2xl:px-[182px] 2xl:py-[235px] 3xl:px-[218px] 3xl:py-[150px] 4xl:px-[420px] 4xl:py-[286px]
    "
  >
    <div v-show="!isMobile" class="indicator-wrapper flex flex-col justify-center items-center h-[100vh]">
      <div
        v-for="(_, index) in slides"
        :key="index"
        class="flex flex-col items-center h-full"
      >
        <span
          class="text-[28px] font-medium mb-4 transition-all duration-300"
          :class="[isActiveSlide(index) ? 'text-[#0C0C0C]' : 'text-[#949494]' ]"
        >
          {{ index + 1 }}
        </span>
        <div
          v-if="index < slides.length - 1"
          :ref="el => lineRefs[index] = el"
          class="line"
        />
      </div>
    </div>

    <div class="slides-container relative w-full h-full overflow-hidden">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        :ref="el => slideRefs[index] = el"
        class="slide h-full p-5 box-border absolute w-full"
      >
        <div class="w-full h-full flex xs:flex-col sm:flex-col md:flex-col xs:items-center sm:items-center md:items-center xs:justify-center sm:justify-center md:justify-center lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row 4xl:flex-row">
          <div class="w-2/5 sm:w-full flex sm:justify-center lg:justify-start">
            <header class="sm:text-center lg:text-left xl:text-left 2xl:text-left 3xl:text-left 4xl:text-left sm:w-6/12">
              <div class="flex justify-center lg:justify-start items-center">
                <span class="text-[#0074E4] ml-1 font-extrabold xs:text-[12px] sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[12px] 3xl:text-[16px] 4xl:text-[21px]">FEATURES</span>
              </div>
              <span class="text-[#1A1A1A] font-semibold sm:text-[32px] md:text-[32px] lg:text-[30px] xl:text-[40px] 2xl:text-[42px] 3xl:text-[56px] 4xl:text-[74px]">{{ slide.title }}</span>
              <div class="text-[#585858] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[17px] 2xl:text-[18px] 3xl:text-[24px] 4xl:text-[32px] sm:leading-[16.8px] md:leading-[16.8px] lg:leading-[17px] xl:leading-[20px] 2xl:leading-[22px] 3xl:leading-[28px] 4xl:leading-[38px]">
                {{ slide.description }}
              </div>
            </header>
          </div>
          <div class="w-3/5 flex items-center justify-center">
            <video
              :src="slide.videoUrl"
              autoplay
              loop
              muted
              playsinline
              class="xs:w-[330px] sm:w-[387px] md:w-[448px] lg:w-[342px] xl:w-[456px] 2xl:w-[481px] 3xl:w-[643px] 4xl:w-[860px]
              xs:h-[400px] sm:h-[463px] md:h-[541px] lg:h-[413px] xl:h-[552px] 2xl:h-[581px] 3xl:h-[777px] 4xl:h-[866px]
            "
            />
          </div>
        </div>
      </div>
    </div>

    <div v-show="isMobile" class="absolute bottom-7 flex w-full justify-center gap-x-5 left-[50%] translate-x-[-50%]">
      <button-round class="size-11 bg-[#272727] text-[#FFFFFF] hover:bg-[#454545]" @click="changeSlide('prev')">
        <icon-arrow-left class="size-6" />
      </button-round>
      <button-round class="size-11 bg-[#272727] text-[#FFFFFF] hover:bg-[#454545]" @click="changeSlide('next')">
        <icon-arrow-right class="size-6" />
      </button-round>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import ButtonRound from '../atoms/ButtonRound.vue';
import IconArrowLeft from '../icons/IconArrowLeft.vue';
import IconArrowRight from '../icons/IconArrowRight.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from '../composables/useBreakpoints';

gsap.registerPlugin(ScrollTrigger);
let tl;

defineProps({
  slides: {
    type: Array,
    default: () => ([])
  }
});

const slideRefs = ref([]);
const lineRefs = ref([]);
const previousProgress = ref(0);
const sectionContainer = ref(null);
const activeIndex = ref(0);

const isActiveSlide = index => index === activeIndex.value;

const changeSlide = direction => {
  let directionIndex = 0;
  if (direction === 'next') {
    directionIndex = activeIndex.value + 1;
    directionIndex %= slideRefs.value.length;
  } else {
    directionIndex = activeIndex.value - 1;
    directionIndex = (directionIndex + slideRefs.value.length) % slideRefs.value.length;

  }
  animateSlideChange(directionIndex, activeIndex.value);
  activeIndex.value = directionIndex;
  const nextProgress = directionIndex / (slideRefs.value.length - 1);
  tl.progress(nextProgress);
};

const mapFilled = Array.from({ length: 4 }, () => ({ isFilled: false, progres: 0 }));

onMounted(() => {
  slideRefs.value.forEach((slide, index) => {
    gsap.set(slide, { opacity: index === 0 ? 1 : 0, scale: index === 0 ? 1 : 0.7, y: index === 0 ? 0 : 100 });
  });

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: sectionContainer.value,
      start: 'top top',
      end: () => `+=${window.innerHeight * (slideRefs.value.length)}`,
      scrub: true,
      pin: true,
      pinSpacing: true,
      onUpdate: self => {
        if (isMobile.value) {
          return;
        }

        const progress = self.progress;
        previousProgress.value = progress;
        const totalSlides = slideRefs.value.length - 1;
        const slideProgress = progress * totalSlides;

        const newActiveIndex = Math.round(slideProgress);

        slideRefs.value.forEach((item, index) => {
          if (lineRefs.value[index]) {
            const fillPercentage = index === 0
              ? Math.min((progress / 0.5) * 100, 100)
              : Math.max(((progress - 0.5) / 0.5) * 100, 0);

            lineRefs.value[index].style.setProperty('--line-fill', `${fillPercentage}%`);
            mapFilled[index] = {
              isFilled: fillPercentage === 100,
              isUnFilled: fillPercentage === 0,
              progres: fillPercentage
            };
          }
        });

        if (newActiveIndex !== activeIndex.value
        && [ 0, 100 ].includes(mapFilled[activeIndex.value].progres)
        && (mapFilled[activeIndex.value].isFilled || mapFilled[newActiveIndex].isUnFilled)) {
          animateSlideChange(newActiveIndex, activeIndex.value);
          activeIndex.value = newActiveIndex;
        }
      }
    }
  });

  if (!isMobile.value) {
    return;
  }
  slideRefs.value.forEach((slide, index) => {
    if (index < slideRefs.value.length - 1) {
      const nextSlide = slideRefs.value[index + 1];

      tl.to(slide, {
        y: 100,
        scale: 0.7,
        opacity: 0,
        ease: 'power1.inOut',
        duration: 1
      });

      tl.fromTo(nextSlide, {
        y: -100,
        scale: 0.1,
        opacity: 0
      }, {
        y: 0,
        scale: 1,
        opacity: 1,
        ease: 'power1.inOut',
        duration: 1
      }, '-=0.5');
    }
  });
});

const animateSlideChange = (newIndex, oldIndex) => {
  const currentSlide = slideRefs.value[oldIndex];
  const nextSlide = slideRefs.value[newIndex];

  if (currentSlide && nextSlide) {
    gsap.to(currentSlide, {
      y: 100,
      scale: 0.7,
      opacity: 0,
      ease: 'power1.inOut',
      duration: 0.8
    });

    gsap.fromTo(
      nextSlide,
      {
        y: -100,
        scale: 0.1,
        opacity: 0
      },
      {
        y: 0,
        scale: 1,
        opacity: 1,
        ease: 'power1.inOut',
        duration: 0.8
      }
    );
  }
};

onBeforeUnmount(() => {
  tl.kill();
});
</script>

<style scoped>
.line {
  width: 1.3px;
  background-color: #949494;
  height: 100%;
  margin-bottom: 16px;
  position: relative;
}
.line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--line-fill, 0%);
  background-color: #0074E4;
}
</style>
