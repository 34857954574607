<template>
  <div v-if="isDevMode" class="z-[100000] bg-white text-gray-900 px-2">
    <div class="xs:hidden">none</div>
    <div class="hidden xs:block sm:hidden">xs</div>
    <div class="hidden sm:block md:hidden">sm</div>
    <div class="hidden md:block lg:hidden">md</div>
    <div class="hidden lg:block xl:hidden">lg</div>
    <div class="hidden xl:block 2xl:hidden">xl</div>
    <div class="hidden 2xl:block 3xl:hidden">2xl</div>
    <div class="hidden 3xl:block 4xl:hidden">3xl</div>
    <div class="hidden 4xl:block">4xl</div>
    <button class="m-2 border rounded p-2 relative z-50" @click="onClick">Go</button>
  </div>
</template>

<script setup>
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';

const isDevMode = import.meta.env.DEV;

async function onClick() {
  console.log('clicked');

  const { data, error } = await useFetch(`${apiDomain}/api/user-program/1`).post().json();

  console.log(data.value, error.value);
}
</script>
