import { ref } from 'vue';
import SHA256 from 'crypto-js/sha256';
import SuperTokens from 'supertokens-web-js';
import ThirdParty from 'supertokens-web-js/recipe/thirdparty';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
// import Passwordless from 'supertokens-web-js/recipe/passwordless';
import Session from 'supertokens-web-js/recipe/session';
import { apiDomain } from './useConstants';

SuperTokens.init({
  appInfo: {
    appName: 'Aristeem',
    apiDomain
  },
  recipeList: [
    EmailPassword.init(),
    ThirdParty.init(),
    // Passwordless.init(),
    Session.init()
  ]
});

const user = ref(null);
const isRequested = ref(false);

function doesSessionExist() {
  return Session.doesSessionExist();
}

async function signIn(email, password) {
  let response;
  try {
    response = await EmailPassword.signIn({
      formFields: [
        {
          id: 'email',
          value: email
        },
        {
          id: 'password',
          value: password
        }
      ]
    });
  } catch (error) {
    return { success: false, error: error.message };
  }

  if (response?.status === 'OK') {
    setUser(response.user);
    return { success: true };
  }

  // known statuses are: OK, WRONG_CREDENTIALS_ERROR, FIELD_ERROR
  return { success: false, error: response?.status };
}

async function signOut() {
  user.value = null;
  await Session.signOut();
}

// NOTE: possibly we should check email for all strategies (email, google, facebook, passwordless)
// https://supertokens.com/docs/passwordless/common-customizations/get-user-info
async function doesEmailExist(email) {
  let response;
  try {
    response = await EmailPassword.doesEmailExist(email);
  } catch (error) {
    return { success: false, error: error.message };
  }

  if (response.status === 'OK') {
    return { success: true, doesExist: response.doesExist };
  }

  return { success: false, error: 'UNKNOWN_RESPONSE' };
}

async function getUserAsync() {
  if (isRequested.value) {
    return user;
  }

  isRequested.value = true;

  const sessionExists = await doesSessionExist();
  if (!sessionExists) {
    return user;
  }

  try {
    const response = await fetch(`${apiDomain}/api/user`);
    if (response.status !== 200) {
      return user;
    }

    const json = await response.json();
    setUser(json);

  } catch (error) {
    console.error('Error getting User', error);
  }

  return user;
}

function getUser() {
  if (!isRequested.value) {
    getUserAsync();
  }

  return user;
}

function setUser(newUser) {
  // FIXME: do better format for user object
  const email = newUser.emails?.[0] || null;
  const avatarUrl = email ? `https://www.gravatar.com/avatar/${SHA256(email)}` : null;

  // NOTE: we can get https://gravatar.com/0f96460995be0af08f001ecc95794ca46d0b15b5700b10eded58660c623911de.json for gravatar profile
  user.value = {
    email,
    avatarUrl,
    raw: newUser
  };
}

export {
  getUser,
  getUserAsync,
  doesSessionExist,
  doesEmailExist,
  signIn,
  signOut
};
