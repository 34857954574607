<template>
  <div class="relative">
    <div class="loader" />
    <svg class="absolute top-0" width="153" height="145" viewBox="0 0 153 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M88.9454 119.446V119.346H88.8454H64.3486H64.2486V119.446V143.943V144.043H64.3486H88.8454H88.9454V143.943V119.446ZM1.10261 143.902L1.03939 144.043H1.19385H27.2364H27.3019L27.3281 143.982L76.498 30.8069L125.668 143.982L125.694 144.043H125.76H152H152.155L152.091 143.902L87.9754 0.959074L87.9489 0.9H87.8841H65.3099H65.2452L65.2187 0.959074L1.10261 143.902Z"
        fill="none"
        stroke="#AFAFAF"
        stroke-width="0.2"
      />
      <defs>
        <clipPath id="myClip">
          <path d="M88.9454 119.446V119.346H88.8454H64.3486H64.2486V119.446V143.943V144.043H64.3486H88.8454H88.9454V143.943V119.446ZM1.10261 143.902L1.03939 144.043H1.19385H27.2364H27.3019L27.3281 143.982L76.498 30.8069L125.668 143.982L125.694 144.043H125.76H152H152.155L152.091 143.902L87.9754 0.959074L87.9489 0.9H87.8841H65.3099H65.2452L65.2187 0.959074L1.10261 143.902Z" />
        </clipPath>
      </defs>
    </svg>
    <slot />
  </div>
</template>

<style scoped>
.loader {
  position: relative;
  width: 160px;
  height: 150px;
  clip-path: url(#myClip);
  border: 1px solid #161616;
}

.loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
  background-color: #FFFFFF;
  animation: moveColor 3s linear 0s;
  animation-fill-mode: forwards;
  z-index: 2;
}

@keyframes moveColor {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
