<template>
  <div ref="cardsRef" class="cards w-[inherit] relative flex justify-center items-center flex-col p-0 mt-0 mx-auto">
    <!--min-h-[100vh]-->
    <div
      v-for="item in cards"
      :key="item.position"
      class="card w-[inherit] bg-white shadow-md rounded-[18px] xs:h-[400px] sm:h-[594px] md:h-[667px] lg:h-[667px] xl:h-[676px] 2xl:h-[742px] 3xl:h-[774px] 4xl:h-[1026px]"
    >
      <div class="w-[inherit] h-[inherit]">
        <div
          v-show="isMobile"
          class="text-[#777777] text-start font-medium xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[12px] xl:text-[16px] 2xl:text-[16px] 3xl:text-[21px] 4xl:text-[28px]
          xs:pt-[40px] xs:pl-[36px] sm:pt-[40px] sm:pl-[36px] md:pt-[40px] md:pl-[36px]
        "
        >
          0{{ item.position }}
        </div>
        <div
          class="h-[calc(100%-2.5rem)] w-[inherit] flex xs:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse xs:items-center sm:items-center md:items-center xs:justify-around sm:justify-around md:justify-around lg:flex-row xl:flex-row 2xl:flex-row 3xl:flex-row 4xl:flex-row justify-center justify-items-center pl-10 pt-10 pr-10 pb-5"
        >
          <div class="w-2/5 xs:w-full sm:w-full flex justify-center items-center">
            <header class="xs:w-[320px] sm:w-[324px] md:w-[381px] lg:w-[202px] xl:w-[260px] 2xl:w-[260px] 3xl:w-[346px] 4xl:w-[462px]">
              <span class="text-[#1A1A1A] font-semibold xs:text-[26px] sm:text-[32px] md:text-[32px] lg:text-[31px] xl:text-[42px] 2xl:text-[42px] 3xl:text-[56px] 4xl:text-[74px]">{{
                item.title
              }}</span>
              <div class="text-[#585858] font-medium xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[18px] 2xl:text-[18px] 3xl:text-[24px] 4xl:text-[32px]">
                {{ item.description }}
              </div>
            </header>
          </div>
          <div class="w-3/5 sm:w-full flex justify-center items-center">
            <div class="xs:w-[350px] sm:w-[363px] md:w-[521.01px] lg:w-[521.01px] xl:w-[650px] 2xl:w-[695px] 3xl:w-[928.6px] 4xl:w-[1241.77px]">
              <video
                :src="item.videoUrl"
                autoplay
                loop
                muted
                defaultMuted
                playsinline
                class="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <div
          v-show="!isMobile"
          class="text-[#777777] text-start font-medium xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[12px] xl:text-[16px] 2xl:text-[16px] 3xl:text-[21px] 4xl:text-[28px]
          absolute
          lg:left-[40px] xl:left-[54px] 2xl:left-[41px] 3xl:left-[53px] 4xl:left-[72px]
          lg:bottom-[26px] xl:bottom-[38px] 2xl:bottom-[28px] 3xl:bottom-[37px] 4xl:bottom-[49px]
        "
        >
          0{{ item.position }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap';
import { isMobile, isLessXl } from '../composables/useBreakpoints';

defineProps({
  cards: {
    type: Array,
    default: () => ([])
  }
});

const cardsRef = ref(null);

onMounted(() => {
  if (!isLessXl.value) {
    const cards = document.querySelectorAll('.card:not(:last-child)');

    gsap.set(cards, { position: 'absolute' });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: cardsRef.value,
        pin: true,
        scrub: 1,
        start: "top -100px",
        end: "bottom top"
      }
    });

    cards.forEach(card => {
      tl.to(card, {
        yPercent: -20,
        duration: 0.3
      })
        .to(card, {
          yPercent: -50,
          duration: 0.3
        })
        .to(card, {
          yPercent: -70,
          duration: 0.3
        })
        .to(card, {
          yPercent: -90,
          duration: 0.3
        })
        .to(card, {
          yPercent: -110,
          duration: 0.3
        })
        .to(card, {
          yPercent: -150,
          duration: 0.3
        });
    });

  } else {
    const cards = document.querySelectorAll('.card');
    cards.forEach(card => {
      card.classList.add('reset-top');
    });
  }
});
</script>

<style scoped>
.cards {
  width: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
.card {
  --spacing: 100px;
  position: relative;
  /* height: calc(100vh - var(--spacing) * 3); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  top: var(--spacing);
  z-index: 3;
  box-sizing: border-box;
}
.card:nth-child(2) {
  top: calc(var(--spacing) * 2);
  z-index: 2;
}

.card:nth-child(3) {
  position: absolute;
  top: calc(var(--spacing) * 3);
  z-index: 1;
}

.card.reset-top {
  top: 100px;
  position: unset;
}
</style>
