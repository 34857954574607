<template>
  <div class="h-full">
    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-40 lg:block lg:overflow-y-auto transition-[width]" :class="isSidebarExpanded ? 'lg:w-sidebar-expanded' : 'lg:w-sidebar-collapsed'">
      <div class="flex flex-col h-full">
        <div class="h-28 flex justify-start items-center shrink-0">
          <router-link to="/" class="transition-[margin] ml-12" exact-active-class="pointer-events-none">
            <company-logo :is-full="isSidebarExpanded" class="text-white h-5" />
          </router-link>
        </div>
        <nav class="grow pl-6 pr-5">
          <slot name="sidebar" :component-class="'flex flex-col space-y-3'" :is-sidebar-expanded="isSidebarExpanded" />
        </nav>
        <div class="flex flex-col shrink-0 justify-center items-center pl-6 pr-5 pb-7 space-y-2">
          <button-side-bar-expand :is-sidebar-expanded="isSidebarExpanded" @click="isSidebarExpanded = !isSidebarExpanded" />
          <toggle-dark-mode :is-sidebar-expanded="isSidebarExpanded" />
        </div>
      </div>
    </div>

    <div class="h-full transition-[padding] relative" :class="isSidebarExpanded ? 'lg:pl-sidebar-expanded' : 'lg:pl-sidebar-collapsed'">
      <div class="relative z-0 h-[84px] lg:h-28 -top-1 custom-backdrop-blur" />

      <div class="h-full flex flex-col">
        <div class="main-container sticky top-0 z-20 flex h-[84px] lg:h-28 shrink-0 items-center gap-x-4 sm:gap-x-6">
          <div class="ml-4 text-white lg:hidden">
            <company-logo class="h-5" />
          </div>

          <div class="flex flex-1 gap-x-2 self-stretch">
            <div class="flex items-center gap-x-2 mr-4 lg:mr-6 ml-auto">
              <slot name="navbar" />
            </div>
          </div>
        </div>

        <main class="grow overflow-y-auto overscroll-none scroll-smooth -mt-28 lg:pt-28 pl-4 lg:pl-1 pr-4 lg:pr-6 pb-20">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>

<script setup>
// NOTE:
// - breakpoint for mobile is 'lg'
// - sidebar widths for normal and expanded view are defined in tailwind.config.js with 'sidebar' and 'sidebar' key

import { shallowRef } from 'vue';

import CompanyLogo from '../atoms/CompanyLogo.vue';
import ButtonSideBarExpand from '../molecules/ButtonSideBarExpand.vue';
import ToggleDarkMode from '../molecules/ToggleDarkMode.vue';

const isSidebarExpanded = shallowRef(false);
</script>
