<template>
  <button-round
    class="h-12 lg:h-16 !justify-start pl-5 pr-8 transition-all w-full !rounded-3xl space-x-4"
    :background-color="color"
  >
    <component :is="icon" class="hidden lg:block size-6 shrink-0" aria-hidden="true" />
    <span class="truncate font-semibold">{{ label }}</span>
  </button-round>
</template>

<script setup>
import { computed } from 'vue';
import ButtonRound from '../atoms/ButtonRound.vue';
import { isMobile } from '../composables/useBreakpoints';

const props = defineProps({
  icon: {
    type: Object,
    default: () => ({})
  },
  label: {
    type: String,
    default: ''
  },
  active: {
    type: Boolean,
    default: false
  }
});

const color = computed(() => {
  if (isMobile.value) {
    return props.active ? 'bg-white' : 'bg-[#272727]';
  }

  return props.active ? 'bg-[#272727]' : 'transparent';
});
</script>
