<template>
  <div class="w-full h-full flex items-end justify-start">
    <loader v-show="showLoader" :duration="duration * 1000" class="fade-loader" />
    <spline-player
      :scene="{
        url: 'https://d9usr6i0h5fem.cloudfront.net/images/public/scene.splinecode',
      }"
      :is-ready-to-show="showLoader"
      class="absolute inset-0 z-0 transition-[opacity,transform] delay-300 duration-1000 pointer-events-none"
      :style="{ opacity: y <= 400 ? opacity : opacityOnScroll, transform: `scale(${scale})`, top: `${topPositionOnScroll}px` }"
      @loaded="onLoaded"
    />

    <div
      v-if="opacity && !showLoader"
      v-motion
      :initial="{ opacity: 0, y: 500 }"
      :enter="{
        opacity: 1,
        y: 0,
        transition: {
          type: 'keyframes',
          ease: 'easeOut',
          duration: 1300,
          delay: 600
        }
      }"
      class="space-y-10 relative"
    >
      <div class="text-white leading-normal text-[38px] sm:text-[38px] md:text-[38px] lg:text-[43px] xl:text-[60px] 2xl:text-[60px] 3xl:text-[80px] 4xl:text-[106px] max-w-[281px] sm:max-w-[281px] md:max-w-[281px] lg:max-w-[317px] xl:max-w-[443px] 2xl:max-w-[443px] 3xl:max-w-[590px] 4xl:max-w-[782px]">
        Launch every program without technical limits
      </div>
      <div>
        <router-link v-slot="{ navigate }" to="/welcome" custom>
          <button-default
            class="!bg-[#0074E4] !border-transparent px-7 font-semibold hover:!bg-[#004180] xs:text-[14px] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[14px] 3xl:text-[18.84px] 4xl:text-[25.13px] xs:px-[26px] xs:py-[22px] sm:px-[26px] sm:py-[22px] md:px-[26px] md:py-[22px] lg:px-[26px] lg:py-[22px] xl:px-[29.92px] xl:py-[24px] 2xl:px-[29.92px] 2xl:py-[24px] 3xl:px-[40.37px] 3xl:py-[32.29px] 4xl:px-[53.71px] 4xl:py-[43.07px]"
            @click="navigate"
          >
            Join waitlist
          </button-default>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import SplinePlayer from '../atoms/SplinePlayer.vue';
import Loader from '../atoms/Loader.vue';
import { useScroll } from '@vueuse/core';

const { y } = useScroll(document);
const topPositionOnScroll = computed(() => Math.min(y.value * 0.2, 500));
const opacityOnScroll = computed(() => {
  const maxScroll = 500;
  return Math.max(1 - y.value / maxScroll, 0.2);
});

const opacity = ref(0);
const scale = ref(1);

const duration = 3;
const showLoader = ref(true);

function onLoaded() {
  scale.value = 1.3;
  setTimeout(() => {
    opacity.value = 1;
    scale.value = 1;
  }, 1000);

}

onMounted(() => {
  setTimeout(() => {
    showLoader.value = false;
  }, (duration + 1) * 1000);
});
</script>
