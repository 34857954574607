<template>
  <div>
    <layout-default @get-is-out-of-view="handleGetIsOutOfView">
      <template #navbar>
        <div class="flex-1 flex justify-center items-center gap-x-2">
          <router-link
            v-for="item in topNavigation"
            :key="item.to"
            v-slot="{ navigate }"
            :to="item.to"
            custom
          >
            <button-default
              v-show="!isMobile"
              class="bg-transparent border-transparent py-7 px-9 hover:bg-[#4B4B4B80] hover:rounded-[32px] font-medium lg:text-[16px] xl:text-[16px] 2xl:text-[16px] 3xl:text-[22px] 4xl:text-[30px]"
              :class="[item.to === $route.path
                ? 'pointer-events-none text-[#A7A7A7]/50'
                : null, isOutOfView ? 'text-[#121212]' : '']"
              @click="navigate"
            >
              {{ item.name }}
            </button-default>
          </router-link>
        </div>

        <div class="shrink-0 flex items-center gap-x-2">
          <!-- <router-link v-slot="{ navigate }" to="/login" custom>
            <button-default
              class="bg-transparent border-transparent px-7"
              @click="navigate"
            >
              Login
            </button-default>
          </router-link> -->

          <top-bar v-if="isMobile">
            <template #sidebar>
              <main-menu :navigation="topNavigationSm" />
            </template>
          </top-bar>
          <router-link v-else v-slot="{ navigate }" to="/welcome" custom>
            <button-default
              class="bg-transparent !border-[#0074E4] hover:!bg-[#004180] hover:!border-[#004180] border-[1.8px] font-semibold lg:text-[14px] xl:text-[14px] 2xl:text-[14px] 3xl:text-[18.84px] 4xl:text-[25.13px] lg:px-[26px] lg:py-[22px] xl:px-[30px] xl:py-[24px] 2xl:px-[30px] 2xl:py-[24px] 3xl:px-[40px] 3xl:py-[32px] 4xl:px-[54px] 4xl:py-[43px]"
              :class="{'text-[#121212] hover:text-white': isOutOfView}"
              @click="navigate"
            >
              Join waitlist
            </button-default>
          </router-link>
        </div>
      </template>

      <router-view v-slot="{ Component, route }">
        <transition
          enter-active-class="transition duration-200"
          enter-from-class="transform-gpu opacity-0"
          enter-to-class="transform-gpu opacity-100"
          leave-active-class="transition duration-200"
          leave-from-class="transform-gpu opacity-100"
          leave-to-class="transform-gpu opacity-0"
          mode="out-in"
        >
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </layout-default>
    <template v-if="route.path === '/'">
      <animated-cards />
      <animated-text />
      <animated-carousel :slides="slides" />
      <animated-video />
      <footer-section />
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import LayoutDefault from '../templates/LayoutDefault.vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import TopBar from '../organisms/TopBar.vue';
import MainMenu from '../organisms/MainMenu.vue';
import AnimatedText from '../sections/AnimatedText.vue';
import AnimatedCards from '../sections/AnimatedCards.vue';
import AnimatedCarousel from '../sections/AnimatedCarousel.vue';
import AnimatedVideo from '../sections/AnimatedVideo.vue';
import FooterSection from '../sections/FooterSection.vue';
import { useRouter, useRoute } from 'vue-router';
import { isMobile } from '../composables/useBreakpoints';

import video1Url from '../assets/videos/illustration_4.mp4';
import video2Url from '../assets/videos/illustration_5.mp4';
import video3Url from '../assets/videos/illustration_6.mp4';

const router = useRouter();
const route = useRoute();

const topNavigation = [
  { name: 'Features', to: '/features' },
  { name: 'Pricing', to: '/pricing' },
  { name: 'Programs', to: '/programs' }
];
const topNavigationSm = [
  { type: 'routerLink', name: 'Features', to: '/features' },
  { type: 'routerLink', name: 'Pricing', to: '/pricing' },
  { type: 'routerLink', name: 'Programs', to: '/programs' },
  {
    type: 'button',
    name: 'Join waitlist',
    to: '/welcome',
    onClick: () => router.push('/welcome')
  }
];
const slides = [
  {
    position: 1,
    title: 'Collaborate',
    description: 'You will be able to share your screen with other users.',
    videoUrl: video1Url
  },
  {
    position: 2,
    title: 'File management',
    description: 'Do with your files whatever you want. Upload, download, edit, share. You decide how to use it.',
    videoUrl: video2Url
  },
  {
    position: 3,
    title: 'Speed',
    description: 'You can forget about inappropriate device. Average launch speed of program 10 seconds even for old tablet.',
    videoUrl: video3Url
  }
];

const isOutOfView = ref(false);
const handleGetIsOutOfView = value => {
  isOutOfView.value = value;
};
</script>
