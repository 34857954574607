<template>
  <div ref="carouselContainer" class="-mx-4 lg:mx-0">
    <Carousel ref="carouselRef" wrap-around :autoplay="autoplay">
      <Slide v-for="(slide, key) in slides" :key="key">
        <program-slider-card :item="slide" />
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import ProgramSliderCard from './ProgramSliderCard.vue';

const carouselContainer = ref(null);
const carouselRef = ref(null);

useResizeObserver(carouselContainer, () => {
  carouselRef.value?.updateSlideWidth();
});

const autoplay = 30 * 1000; // 30sec
// FIXME: autopla

defineProps({
  slides: {
    type: Array,
    default: () => []
  }
});
</script>
