<template>
  <div class="flex flex-col w-full min-h-full px-6">
    <div class="grow flex justify-center items-center relative 2xl:container 2xl:mx-auto">
      <slot />
    </div>
    <div v-if="slots.footer" class="shrink-0">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();
</script>
