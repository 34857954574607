<template>
  <layout-sidebar>
    <template #navbar>
      <top-bar :navigation="topNavigation" :user-navigation="userNavigation">
        <template #sidebar>
          <main-menu :navigation="mainMenuNavigation" />
        </template>
      </top-bar>
    </template>

    <template #sidebar="{ isSidebarExpanded, componentClass }">
      <side-bar :is-sidebar-expanded="isSidebarExpanded" :navigation="sidebarNavigation" :class="componentClass" />
    </template>

    <router-view v-slot="{ Component, route }">
      <transition
        enter-active-class="transition duration-200"
        enter-from-class="transform-gpu opacity-0"
        enter-to-class="transform-gpu opacity-100"
        leave-active-class="transition duration-200"
        leave-from-class="transform-gpu opacity-100"
        leave-to-class="transform-gpu opacity-0"
        mode="out-in"
      >
        <component :is="Component" :key="route.path" v-model:selected="selected" @open-search-form="openSearchForm" />
      </transition>
    </router-view>

    <form-search ref="searchForm" @change="onFilterChanged" />
  </layout-sidebar>
</template>

<script setup>
import { ref } from 'vue';
import LayoutSidebar from '../templates/LayoutSidebar.vue';
import TopBar from '../organisms/TopBar.vue';
import SideBar from '../organisms/SideBar.vue';
import MainMenu from '../organisms/MainMenu.vue';
import FormSearch from '../organisms/FormSearch.vue';
import { useRouter } from 'vue-router';

import IconLibrary from '../icons/IconLibrary.vue';
import IconMyPrograms from '../icons/IconMyPrograms.vue';
import IconMyFiles from '../icons/IconMyFiles.vue';
import IconHeadphones from '../icons/IconHeadphones.vue';
import IconGlass from '../icons/IconGlass.vue';
import IconLogOut from '../icons/IconLogOut.vue';
import IconMoon from '../icons/IconMoon.vue';

const router = useRouter();
const searchForm = ref(null);
const selected = ref({});

function openSearchForm() {
  searchForm.value.open(selected.value);
}

function onFilterChanged(_selected) {
  if (router.currentRoute.value.path !== '/library') {
    router.push({ path: '/library' });
  }
  selected.value = _selected;
}

const topNavigation = [
  { icon: IconGlass, name: 'Search', onClick: openSearchForm },
  { icon: IconHeadphones, name: 'Support', onClick: () => console.log('Support') }
];

const userNavigation = [
  { name: 'Your profile', to: '/FIXME' },
  { name: 'Sign out', to: '/logout', icon: IconLogOut }
];

const sidebarNavigation = [
  { name: 'Library', to: '/library', icon: IconLibrary },
  { name: 'My programs', to: { name: 'PrivatePrograms' }, icon: IconMyPrograms },
  { name: 'My files', to: '/files', icon: IconMyFiles }
];

const mainMenuNavigation = [
  { type: 'routerLink', name: 'Library', to: '/library', icon: IconLibrary },
  { type: 'routerLink', name: 'My programs', to: { name: 'PrivatePrograms' }, icon: IconMyPrograms },
  { type: 'routerLink', name: 'My files', to: '/files', icon: IconMyFiles },
  { type: 'separator', name: 'separator1' },
  { type: 'button', name: 'Search', icon: IconGlass, onClick: openSearchForm },
  { type: 'button', name: 'Support', icon: IconHeadphones, onClick: () => console.log('Support') },
  { type: 'button', name: 'Switch theme', icon: IconMoon, onClick: () => console.log('Swithch theme') },
  { type: 'separator', name: 'separator2' },
  { type: 'routerLink', name: 'Sign out', to: '/logout', icon: IconLogOut }
];
</script>
