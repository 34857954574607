<template>
  <div ref="animatedVideoRef" class="relative w-screen h-screen bg-[#ffffff] overflow-hidden -mt-px">
    <div class="h-[100vh] p-16 relative">
      <div class="bg-[#f4f5f7] absolute z-0 transition-all duration-500" :class="isReachingToOutOfView ? '-inset-10' : 'inset-0 rounded-tl-[80px] rounded-tr-[80px]'" />
      <div ref="youGet" class="absolute inset-0 flex justify-center items-center text-[#1A1A1A] font-semibold sm:text-[71px] md:text-[71px] lg:text-[71px] xl:text-[95px] 2xl:text-[100px] 3xl:text-[133px] 4xl:text-[177px]">
        You get
      </div>
      <video
        ref="firstVideo"
        :src="firstVideoMp4"
        type="video/mp4"
        autoplay
        loop
        muted
        defaultMuted
        playsinline
        class="absolute w-screen h-screen object-cover rounded-tl-[40px] rounded-bl-[40px]"
      />

      <button
        ref="firstButton"
        class="fixed bg-[#FFFFFF] text-black rounded-full font-semibold shadow-lg opacity-0 xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[17px] 2xl:text-[18px] 3xl:text-[24px] 4xl:text-[32px]
        xs:px-[32px] xs:py-[18px] sm:px-[32px] sm:py-[18px] md:px-[32px] md:py-[18px] lg:px-[32px] lg:py-[18px] xl:px-[47px] xl:py-[24px] 2xl:px-[45px] 2xl:py-[25px] 3xl:px-[60px] 3xl:py-[33px] 4xl:px-[80px] 4xl:py-[45px]
        4xl:min-w-[544px] 3xl:min-w-[407px] 2xl:min-w-[305px] xl:min-w-[289px] lg:min-w-[231px] md:min-w-[231px] sm:min-w-[231px] xs:min-w-[220px]
        "
        @click="testClick('Always updated programs')"
      >
        Always updated programs
      </button>

      <div ref="secondSectionContainer" class="absolute w-screen h-screen top-0 left-0 bg-[#f4f5f7] flex justify-center items-center scale-[7] opacity-0">
        <div ref="imageScreen" class="image-container">
          <img :src="img2Url" class="background-image">
          <div class="video-overlay">
            <video ref="video" autoplay muted defaultMuted loop class="video-content">
              <source :src="firstVideoMp4" type="video/mp4">
            </video>
          </div>
        </div>

        <div ref="smallImageScreen" class="absolute opacity-0 flex justify-center">
          <img :src="bgMini" class="xs:h-[230px] sm:h-[295px] md:h-[518px] lg:h-[524px] xl:h-[570px] 2xl:h-[601px] 3xl:h-[803px] 4xl:h-[1074px]">
        </div>
      </div>

      <button
        ref="secondButton"
        class="fixed px-6 py-3 bg-[#FFFFFF] text-black rounded-full font-semibold shadow-lg opacity-0 xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[17px] 2xl:text-[18px] 3xl:text-[24px] 4xl:text-[32px]
        xs:px-[32px] xs:py-[18px] sm:px-[32px] sm:py-[18px] md:px-[32px] md:py-[18px] lg:px-[32px] lg:py-[18px] xl:px-[47px] xl:py-[24px] 2xl:px-[45px] 2xl:py-[25px] 3xl:px-[60px] 3xl:py-[33px] 4xl:px-[80px] 4xl:py-[45px]
        4xl:min-w-[544px] 3xl:min-w-[407px] 2xl:min-w-[305px] xl:min-w-[289px] lg:min-w-[231px] md:min-w-[231px] sm:min-w-[231px] xs:min-w-[220px]
        "
        @click="testClick('Intuitive interface')"
      >
        Intuitive interface
      </button>

      <button
        ref="thirdButton"
        class="fixed px-6 py-3 bg-[#FFFFFF] text-black rounded-full font-semibold shadow-lg opacity-0 xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[17px] 2xl:text-[18px] 3xl:text-[24px] 4xl:text-[32px]
        xs:px-[32px] xs:py-[18px] sm:px-[32px] sm:py-[18px] md:px-[32px] md:py-[18px] lg:px-[32px] lg:py-[18px] xl:px-[47px] xl:py-[24px] 2xl:px-[45px] 2xl:py-[25px] 3xl:px-[60px] 3xl:py-[33px] 4xl:px-[80px] 4xl:py-[45px]
        4xl:min-w-[544px] 3xl:min-w-[407px] 2xl:min-w-[305px] xl:min-w-[289px] lg:min-w-[231px] md:min-w-[231px] sm:min-w-[231px] xs:min-w-[220px]
        "
        @click="testClick('For every device')"
      >
        For every device
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { gsap } from 'gsap';
import { syncVideos } from '../utils/domUtils/syncVideos';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import firstVideoMp4 from '../assets/videos/section_4_video.mp4';
import img1Url from '../assets/images/bg2-video-4-section.png';
import img2Url from '../assets/images/bg-video-4-section.png';
import bgMini from '../assets/images/bg-mini.png';

gsap.registerPlugin(ScrollTrigger);
let tl;

const animatedVideoRef = ref(null);

const youGet = ref(null);
const firstVideo = ref(null);
const secondSectionContainer = ref(null);
const firstButton = ref(null);
const secondButton = ref(null);
const thirdButton = ref(null);
const imageScreen = ref(null);
const video = ref(null);
const smallImageScreen = ref(null);

const testClick = val => {
  console.log('testClick', val);
};

const isReachingToOutOfView = ref(false);

useIntersectionObserver(
  animatedVideoRef,
  ([ entry ]) => {
    isReachingToOutOfView.value = entry.isIntersecting;
  },
  {
    threshold: 0.6
  }
);

onMounted(() => {
  syncVideos([ firstVideo.value, video.value ]);

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: animatedVideoRef.value,
      start: 'top top',
      end: '+=400%',
      scrub: 3,
      pin: true,
      toggleActions: 'restart none none none',
      anticipatePin: 1
    }
  });

  // Animation of text and red block when scrolling downward
  tl.to(youGet.value, {
    x: '-100%',
    ease: 'power1.inOut'
  });

  tl.fromTo(
    firstVideo.value,
    {
      x: () => `${firstVideo.value.offsetWidth}`,
      height: '50vh',
      width: '100vw',
      top: '50%',
      yPercent: -50
    },
    { x: '0%',
      height: '100vh',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      yPercent: -50,
      ease: 'power1.inOut' },
    '-=0.6' // '<'
  );

  /* tl.fromTo(
    firstVideo.value,
    {
      x: () => `${firstVideo.value.offsetWidth}`,
      height: '50vh',
      top: '50%',
      yPercent: -50
    },
    {
      x: 0,
      height: '100vh',
      yPercent: -50,
      keyframes: [
        {
          x: () => `${window.innerWidth * 0.25}px`,
          height: '75vh'
        },
        {
          x: 0,
          height: '100vh'
        }
      ],
      ease: 'power1.inOut',
      scrollTrigger: {
        trigger: animatedVideoRef.value,
        start: 'top center',
        end: '+=400%',
        scrub: true
      }
    }
  ); */

  tl.to(firstVideo.value, {
    scale: 2,
    ease: 'power1.inOut',
    duration: 1
  });

  tl.to(firstVideo.value, {
    scale: 1.5,
    ease: 'power1.inOut',
    duration: 1
  });

  // Animation of button appearance
  tl.fromTo(
    firstButton.value,
    { left: '100%',
      bottom: -100,
      opacity: 0,
      scale: 0
    },
    {
      left: '50%',
      bottom: '2vh',
      translateX: '-50%',
      opacity: 1,
      scale: 1,
      zIndex: 1,
      ease: 'back.out(1.7)',
      duration: 0.1
    }
  );

  // Transition to green screen with skylight animation
  tl.to(youGet.value, {
    opacity: 0,
    duration: 1,
    onComplete: () => {
      gsap.set(secondSectionContainer.value, { opacity: 0.1, scale: 10, zIndex: 1 });
    }
  });

  // Hide button 1
  tl.to(
    firstButton.value,
    {
      opacity: 0
    }
  );

  // Button 2 appears after the green screen animation ends
  tl.fromTo(
    secondButton.value,
    {
      opacity: 0,
      left: '50%',
      bottom: '2vh',
      translateX: '-50%'
    },
    {
      opacity: 1,
      bottom: '2vh',
      left: '50%',
      zIndex: 1,
      delay: 0.5
    }
  );

  tl.fromTo(
    secondSectionContainer.value,
    { scale: 10, opacity: 0 },
    { scale: 1,
      opacity: 1,
      ease: 'power1.inOut',
      duration: 1,
      onComplete: () => {
        secondSectionContainer.value.classList.add('bg-grid-pattern');
      },
      onReverseComplete: () => {
        secondSectionContainer.value.classList.remove('bg-grid-pattern');
      }
    }
  );

  tl.fromTo(
    imageScreen.value,
    { scale: 1, opacity: 1 },
    { scale: 0.7, opacity: 1, ease: 'power1.inOut', duration: 1 }
  );
  tl.to(
    imageScreen.value,
    {
      width: '90vw',
      onComplete: () => {
        const imageElement = imageScreen.value.querySelector('.background-image');
        if (imageElement) {
          gsap.to(video.value, {
            opacity: 0,
            duration: 0.1
          });
          gsap.to(imageElement, {
            opacity: 0,
            duration: 0.1,
            onComplete: () => {
              imageElement.src = img1Url;
              gsap.to(imageElement, {
                opacity: 1,
                duration: 0.1
              });
              gsap.to(video.value, {
                opacity: 1,
                duration: 0.1
              });
            }
          });
        }
      },
      onReverseComplete: () => {
        const imageElement = imageScreen.value.querySelector('.background-image');
        if (imageElement) {
          gsap.to(video.value, {
            opacity: 0,
            duration: 0.1
          });
          gsap.to(imageElement, {
            opacity: 0,
            duration: 0.1,
            onComplete: () => {
              imageElement.src = img2Url;
              gsap.to(imageElement, {
                opacity: 1,
                duration: 0.1
              });
              gsap.to(video.value, {
                opacity: 1,
                duration: 0.1
              });
            }
          });
        }
      }
    }
  );

  // Hide button 2
  tl.to(
    secondButton.value,
    {
      opacity: 0
    }
  );

  // Button 3 appears after the green screen animation ends
  tl.fromTo(
    thirdButton.value,
    {
      opacity: 0,
      left: '50%',
      bottom: '2vh',
      translateX: '-50%'
    },
    {
      opacity: 1,
      bottom: '2vh',
      left: '50%',
      zIndex: 1,
      delay: 0.5
    }
  );

  tl.to(imageScreen.value, {
    opacity: 0,
    width: '50vw',
    duration: 1,
    onComplete: () => {
      gsap.set(smallImageScreen.value, { opacity: 1, zIndex: 1 });
    }
  });
  // Add an animation for smallImageScreen after the previous animation has finished
  tl.fromTo(
    smallImageScreen.value,
    { opacity: 0 },
    { opacity: 1, duration: 0.5, ease: 'power1.inOut' }
  );
});

onUnmounted(() => {
  tl.kill();
});
</script>
<style scoped>
.image-container {
  position: relative;
  width: 100vw;
  /* height: 100vh; */
}

.background-image {
  width: 100%;
  height: 100%;
  display: block;
}

.video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 20%;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #2b2e32;
  transform: translate(-39%, 135%);
}

.video-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-grid-pattern {
  background-image:
      linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
}

</style>
