<template>
  <div class="inline-block relative">
    <input
      ref="input"
      v-bind="$attrs"
      v-model="model"
      class="h-[50px] bg-[#1B1B1B] text-white rounded-full border border-[#272727] outline-none px-12 text-sm placeholder:text-[#A7A7A7]/50"
    >
    <icon-search class="absolute top-1/2 left-5 -translate-y-1/2 text-white size-4" />
    <button-round v-if="!!model" class="size-8 absolute top-1/2 right-3 -translate-y-1/2 hover:bg-black" @click="reset">
      <icon-close class="text-[#A7A7A7] size-8" />
    </button-round>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import ButtonRound from './ButtonRound.vue';
import IconClose from '../icons/IconClose.vue';
import IconSearch from '../icons/IconSearch.vue';

const props = defineProps({
  error: {
    type: String,
    default: ''
  }
});

const input = ref(null);
const model = defineModel({ type: String, default: '' });

watch(() => props.error, async value => {
  console.log('error', value);
  input.value.focus();
  input.value.setCustomValidity(value);
  await nextTick();
  input.value.reportValidity();
});

watch(() => model.value, () => {
  input.value.setCustomValidity('');
});

function reset() {
  model.value = '';
  input.value.focus();
}
</script>
