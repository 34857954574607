<template>
  <button-default :disabled="isLoading" @click.stop="onClick">
    <div class="w-14 flex justify-center items-center">
      <the-spinner v-if="isLoading" class="size-4" />
      <template v-else>
        Launch
      </template>
    </div>
  </button-default>
</template>

<script setup>
import { ref } from 'vue';
import ButtonDefault from './ButtonDefault.vue';
import TheSpinner from './TheSpinner.vue';
import { useProgramLaunch } from '../composables/useProgramLaunch';
import { getUser } from '../composables/useAuth';
import { useRouter } from 'vue-router';

const user = getUser();
const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
});

const isLoading = ref(false);

function onClick() {
  if (!user.value) {
    router.push('/welcome');
    return;
  }

  useProgramLaunch(props.item.automationAppId, props.item.splashImageUrl);
}
</script>
