<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      class="fixed z-30 top-0 left-0 right-0 bottom-0 lg:top-4 lg:left-1/2 lg:bottom-auto lg:right-auto lg:-translate-x-1/2"
      @close="close"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0 scale-0"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          class="bg-[#212121] z-30 px-4 lg:px-6 pt-6 pb-3 w-full lg:w-[720px] max-w-full h-full lg:h-[70vh] lg:rounded-3xl"
        >
          <div class="w-full h-full flex flex-col">
            <div class="shrink-0">
              <input-search
                v-model="searchString"
                class="w-full h-[52px]"
                placeholder="Search"
              />
            </div>

            <div class="grow overflow-hidden relative">
              <div
                v-if="stats"
                class="flex flex-col lg:flex-row gap-x-4 h-full"
              >
                <ul
                  v-if="!searchString"
                  role="list"
                  class="shrink-0 lg:w-52 lg:h-full lg:flex-col overflow-auto py-6 flex gap-3"
                >
                  <li v-for="item in sections" :key="item.id">
                    <button-search-form
                      :class="{
                        'pointer-events-none': item.id === currentSection
                      }"
                      :icon="item.icon"
                      :label="item.name"
                      :active="item.id === currentSection"
                      @click="setSection(item.id)"
                    />
                  </li>
                </ul>

                <div
                  v-if="checkboxItems"
                  class="space-y-1 w-full h-full overflow-y-auto py-6"
                >
                  <search-form-checkbox
                    v-for="item in checkboxItems"
                    :key="item.id"
                    :item="item"
                    :selected="isSelected(item.id)"
                    class="cursor-pointer"
                    @change="onChange(item.id)"
                    @click.prevent="onChange(item.id)"
                  />
                </div>

                <div
                  v-else
                  class="space-y-1 w-full h-full overflow-y-auto py-6"
                >
                  <search-form-results
                    :section="currentSection"
                    :search-string="searchDebounced"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="checkboxItems"
              class="shrink-0 flex justify-end items-center gap-x-3 sm:mt-3 pt-3 border-t border-[#272727]"
            >
              <button-default
                class="h-[52px] px-6 bg-transparent border-transparent"
                @click="close"
              >
                Cancel
              </button-default>

              <button-default class="h-[52px] px-6" @click="apply">
                Apply
              </button-default>
            </div>
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, ref, shallowRef } from 'vue';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue';
import { refDebounced } from '@vueuse/core';
import InputSearch from '../atoms/InputSearch.vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import ButtonSearchForm from '../molecules/ButtonSearchForm.vue';
import SearchFormCheckbox from '../molecules/SearchFormCheckbox.vue';
import SearchFormResults from '../molecules/SearchFormResults.vue';
import IconCategory from '../icons/IconCategory.vue';
import IconTopFree from '../icons/IconTopFree.vue';
import IconTopPaid from '../icons/IconTopPaid.vue';
import IconNew from '../icons/IconNew.vue';
import IconDeveloper from '../icons/IconDeveloper.vue';
import IconPublisher from '../icons/IconPublisher.vue';
import { getStats } from '../composables/useProgramStats';

defineExpose({ open, close });

const emit = defineEmits([ 'change' ]);

const sections = [
  {
    id: 'categories',
    name: 'Categories',
    icon: IconCategory
  },
  {
    id: 'free',
    name: 'Top free',
    icon: IconTopFree
  },
  {
    id: 'paid',
    name: 'Top paid',
    icon: IconTopPaid
  },
  {
    id: 'new',
    name: 'New',
    icon: IconNew
  },
  {
    id: 'developers',
    name: 'Developer',
    icon: IconDeveloper
  },
  {
    id: 'publishers',
    name: 'Publisher',
    icon: IconPublisher
  }
];

const isOpen = shallowRef(false);
const searchString = ref('');
const searchDebounced = refDebounced(searchString, 300);
const currentSection = ref(sections[0].id);
const selectedCategories = ref([]);

const stats = getStats();

const checkboxItems = computed(() => {
  if (searchString.value) {
    return null;
  }

  if (currentSection.value === 'categories') {
    return stats.value.categories;
  }

  if (currentSection.value === 'publishers') {
    return stats.value.publishers;
  }

  if (currentSection.value === 'developers') {
    return stats.value.developers;
  }

  return null;
});

function open(selected = {}) {
  selectedCategories.value = selected.ids || [];
  currentSection.value = selected.section || sections[0].id;
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function setSection(id) {
  currentSection.value = id;
  selectedCategories.value = [];
  // emit('change', { section: null, ids: [] });
}

function isSelected(id) {
  return selectedCategories.value.includes(id);
}

function onChange(id) {
  const index = selectedCategories.value.indexOf(id);
  if (index === -1) {
    selectedCategories.value.push(id);
  } else {
    selectedCategories.value.splice(index, 1);
  }
}

function apply() {
  emit('change', {
    section: currentSection.value,
    ids: [ ...selectedCategories.value ]
  });
  close();
}
</script>
