<template>
  <div v-if="isMobile">
    <button-burger @click="isSidebarOpen = true" />

    <TransitionRoot as="template" :show="isSidebarOpen">
      <Dialog as="div" class="relative z-40" @close="isSidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-neutral-900/80 backdrop-blur-sm" />
        </TransitionChild>

        <div class="fixed inset-0 flex justify-end">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel class="relative ml-16 flex w-full max-w-[80vw] flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute right-full top-2.5 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="isSidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <icon-remove class="h-6 w-6 text-white/70 hover:text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>

              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-900 p-6 ring-1 ring-white/10">
                <nav class="flex flex-1 flex-col" @click="isSidebarOpen = false">
                  <slot name="sidebar" :component-class="'-mx-2 flex-1 space-y-1'" />
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>

  <div v-else class="flex items-center gap-x-2">
    <button-top-bar v-for="item in navigation" :key="item.name" :item="item" @click="item.onClick" />

    <dropdown-default :items="userNavigation" @update:model-value="e => router.push(e.to)">
      <user-avatar />
    </dropdown-default>
  </div>
</template>

<script setup>
import { shallowRef } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';
import DropdownDefault from './DropdownDefault.vue';
import IconRemove from '../icons/IconRemove.vue';
import ButtonTopBar from '../molecules/ButtonTopBar.vue';
import ButtonBurger from '../molecules/ButtonBurger.vue';
import UserAvatar from '../molecules/UserAvatar.vue';
import { isMobile } from '../composables/useBreakpoints';
import { useRouter } from 'vue-router';

defineProps({
  navigation: {
    type: Array,
    default: () => []
  },
  userNavigation: {
    type: Array,
    default: () => []
  }
});

const isSidebarOpen = shallowRef(false);
const router = useRouter();
</script>
