<template>
  <div class="inline-block relative">
    <input
      ref="input"
      v-bind="$attrs"
      v-model="model"
      class="h-[50px] bg-[#1B1B1B] autofill:bg-[#1B1B1B] rounded-full border border-[#272727] outline-none pl-[30px] pr-[45px] text-white text-sm placeholder:text-[#A7A7A7]/50"
      :class="(!!model && !isPasswordVisible) ? 'font-[caption]' : null"
      name="password"
      autocomplete="current-password"
      :type="isPasswordVisible ? 'text' : 'password'"
    >
    <component
      :is="isPasswordVisible ? IconEyeCross : IconEye"
      class="absolute top-1/2 right-[18px] -translate-y-1/2 text-white size-4 cursor-pointer hover:opacity-80"
      @click="isPasswordVisible = !isPasswordVisible"
    />
  </div>
</template>

<script setup>
import { nextTick, ref, watch } from 'vue';
import IconEye from '../icons/IconEye.vue';
import IconEyeCross from '../icons/IconEyeCross.vue';

const props = defineProps({
  error: {
    type: String,
    default: ''
  }
});

const model = defineModel({ type: String, default: '' });
const input = ref(null);
const isPasswordVisible = ref(false);

watch(() => props.error, async value => {
  input.value.focus();
  input.value.setCustomValidity(value);
  await nextTick();
  input.value.reportValidity();
});

watch(() => model.value, () => {
  input.value.setCustomValidity('');
});
</script>
