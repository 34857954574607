<template>
  <form class="w-full max-w-sm flex flex-col gap-y-6" @submit.prevent="submit">
    <component-blur as="h1" class="text-[#D2D2D2] text-5xl font-bold text-center" :is-blured="isBlured">
      <template v-if="isSuccess">
        Check your inbox
      </template>
      <template v-else-if="isWelcome">
        Welcome
      </template>
      <template v-else>
        Login
      </template>
    </component-blur>

    <component-blur class="text-sm text-[#747474] text-center" :is-blured="isBlured">
      <template v-if="isSuccess">
        We have sent you a secure login link and temporary password.
        <br>
        Please click the link to authenticate your account.
      </template>
      <template v-else>
        Please enter your email to log in to account
      </template>
    </component-blur>

    <div>
      <input-default
        v-model="email"
        v-focus
        type="email"
        placeholder="Enter your email"
        autocomplete="email"
        class="w-full"
        :readonly="isEmailInputReadonly"
        :is-valid="isSuccess || isPasswordAsking || isEmailResending"
        :error="emailError"
        required
      />
    </div>

    <div v-if="isPasswordAsking">
      <input-password
        ref="passwordRef"
        v-model="password"
        v-focus
        placeholder="Enter your password"
        class="w-full"
        :readonly="isPasswordInputReadonly"
        :error="passwordError"
        required
      />
    </div>

    <div v-if="isSuccess" class="text-sm text-[#747474] text-center">
      Didn’t receive an email?
      <button type="button" class="link ml-1" :disabled="isLoading" @click.prevent="resend">
        Resend email
      </button>
    </div>

    <div v-else-if="isPasswordAsking" class="text-sm text-[#747474] text-center">
      Forgot password?
      <button type="button" class="link ml-1" :disabled="isLoading" @click.prevent="resend">
        Reset and get the login link
      </button>
    </div>

    <div v-if="!isSuccess">
      <button-default type="submit" class="w-full">
        <the-spinner v-if="isLoading" class="size-8 text-white" />
        <template v-else-if="isPasswordAsking">
          Login
        </template>
        <template v-else>
          Continue with email
        </template>
      </button-default>
    </div>

    <component-blur v-if="!isSuccess" class="flex flex-col mx-auto gap-y-1" :is-blured="isBlured">
      <div class="flex items-center gap-x-3 px-10 py-1.5 rounded-full hover:bg-[#171717] hover:ring-1 ring-white/10 cursor-pointer text-sm">
        <icon-facebook class="size-6" />
        Continue with Facebook
      </div>

      <div class="flex items-center gap-x-3 px-10 py-1.5 rounded-full hover:bg-[#171717] hover:ring-1 ring-white/10 cursor-pointer text-sm">
        <icon-google class="size-6" />
        Continue with Google
      </div>
    </component-blur>
  </form>
</template>

<script setup>
import { ref, computed } from 'vue';
import InputDefault from '../atoms/InputDefault.vue';
import InputPassword from '../atoms/InputPassword.vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import ComponentBlur from '../atoms/ComponentBlur.vue';
import TheSpinner from '../atoms/TheSpinner.vue';
import IconFacebook from '../icons/IconFacebook.vue';
import IconGoogle from '../icons/IconGoogle.vue';
import {
  STATE_LOGIN_INITIAL,
  STATE_LOGIN_WELCOME,
  STATE_LOGIN_EMAIL_CHECKING,
  STATE_LOGIN_EMAIL_SUCCESS,
  STATE_LOGIN_EMAIL_FAIL,
  STATE_LOGIN_EMAIL_RESEND,
  STATE_LOGIN_PASSWORD_ASK,
  STATE_LOGIN_PASSWORD_CHECKING,
  // STATE_LOGIN_PASSWORD_SUCCESS,
  STATE_LOGIN_PASSWORD_FAIL
} from '../composables/useConstants';

const props = defineProps({
  state: {
    type: Number,
    default: null
  }
});

const emit = defineEmits([ 'submit' ]);

const email = ref('');
const password = ref('');

const isBlured = computed(() => props.state !== STATE_LOGIN_EMAIL_SUCCESS && Boolean(email.value));
const isWelcome = computed(() => props.state === STATE_LOGIN_WELCOME);
const isEmailInputReadonly = computed(() => props.state !== STATE_LOGIN_INITIAL && props.state !== STATE_LOGIN_WELCOME && props.state !== STATE_LOGIN_EMAIL_FAIL);
const isLoading = computed(() => props.state === STATE_LOGIN_EMAIL_CHECKING || props.state === STATE_LOGIN_EMAIL_RESEND || props.state === STATE_LOGIN_PASSWORD_CHECKING);
const isSuccess = computed(() => props.state === STATE_LOGIN_EMAIL_SUCCESS);
const isPasswordAsking = computed(() => props.state === STATE_LOGIN_PASSWORD_ASK || props.state === STATE_LOGIN_PASSWORD_CHECKING || props.state === STATE_LOGIN_PASSWORD_FAIL);
const isPasswordInputReadonly = computed(() => props.state !== STATE_LOGIN_PASSWORD_ASK && props.state !== STATE_LOGIN_PASSWORD_FAIL);
const isEmailResending = computed(() => props.state === STATE_LOGIN_EMAIL_RESEND);
const passwordError = computed(() => (props.state === STATE_LOGIN_PASSWORD_FAIL ? 'Invalid password' : ''));
const emailError = computed(() => (props.state === STATE_LOGIN_EMAIL_FAIL ? 'Email not found' : ''));

function submit() {
  if ((props.state === STATE_LOGIN_INITIAL || props.state === STATE_LOGIN_WELCOME || props.state === STATE_LOGIN_EMAIL_FAIL) && email.value) {
    emit('submit', { state: props.state, email: email.value });
    return;
  }

  if ((props.state === STATE_LOGIN_PASSWORD_ASK || props.state === STATE_LOGIN_PASSWORD_FAIL) && email.value && password.value) {
    emit('submit', { state: STATE_LOGIN_PASSWORD_ASK, email: email.value, password: password.value });
    return;
  }

  console.log('Invalid state');
}

function resend() {
  emit('submit', { state: STATE_LOGIN_EMAIL_RESEND, email: email.value });
}
</script>
