<template>
  <button-round class="bg-[#1B1B1B] [&:not(:hover)]:text-white relative">
    <div class="text-lg font-medium leading-none">{{ firstLetter }}</div>
    <div v-if="user?.avatarUrl" class="absolute inset-0">
      <img :src="user.avatarUrl" alt="User avatar" class="size-full rounded-full">
    </div>
  </button-round>
</template>

<script setup>
import { computed } from 'vue';
import { getUser } from '../composables/useAuth';
import ButtonRound from '../atoms/ButtonRound.vue';

const user = getUser();

const firstLetter = computed(() => user.value?.email?.[0]?.toUpperCase());
</script>
