<template>
  <div class="w-full flex flex-col space-y-4 animate-pulse">
    <div class="w-full flex items-center space-x-4">
      <div class="shrink-0 size-[52px] rounded-2xl flex justify-center items-center bg-[#272727]" />

      <div class="flex-1 flex-col flex justify-start items-start space-y-1">
        <div class="w-2/5 h-4 bg-[#272727] rounded" />
        <div class="w-3/4 h-4 bg-[#272727] rounded" />
      </div>
    </div>
    <div class="w-full aspect-[2/1] rounded-lg overflow-hidden bg-[#272727]" />
  </div>
</template>
