<template>
  <RouterView />
  <DevTool class="fixed left-1 top-1 rounded opacity-50" />
</template>

<script setup>
import DevTool from './atoms/DevTool.vue';
import { getStats } from './composables/useProgramStats';

getStats();
</script>
