export function syncVideos(videoElements) {
  const playPromise = videoElements[0].play();

  if (playPromise !== undefined) {
    playPromise.then(() => {
      videoElements.forEach(video => {
        video.currentTime = 0;
        video.play();
      });
    }).catch(error => {
      console.error('Error when trying to play a video:', error);
    });
  }
}
