<template>
  <div class="2xl:container 2xl:mx-auto mt-28 lg:mt-0">
    <button-back @click="router.back" />

    <template v-if="item">
      <div class="flex items-center gap-x-5 mt-5">
        <div class="shrink-0">
          <img :src="item.iconLargeUrl" class="size-32" :alt="item.name + ' icon'">
        </div>
        <div class="w-full">
          <div class="text-white text-[32px] leading-[42px] font-semibold truncate">{{ item.name }}</div>
          <div class="text-[#A7A7A7] text-sm truncate">{{ item.description }}</div>
          <div class="flex mt-4 gap-x-3">
            <button-launch class="px-4 !h-[40px]" :item="item" />
            <dropdown-default v-if="user" :items="contextMenuList" menu-placement-left>
              <button-round class="size-[40px] hover:bg-[#272727]" background-color="transparent">
                <icon-dots-vertical class="size-4" />
              </button-round>
            </dropdown-default>
          </div>
        </div>
      </div>

      <div class="border-t border-b border-[#1B1B1B] mt-10 py-6 grid items-center grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-y-6">
        <div class="flex flex-col items-center gap-y-1 text-nowrap">
          <div class="text-sm text-[#A7A7A7] font-medium">License type</div>
          <div class="flex items-center gap-x-2">
            <icon-arrow-link class="size-4 text-white" />
            <div class="text-white text-base font-semibold capitalize">{{ item.licenseType }}</div>
          </div>
        </div>
        <div class="flex flex-col items-center gap-y-1 text-nowrap">
          <div class="text-sm text-[#A7A7A7] font-medium">Publisher</div>
          <div class="flex items-center gap-x-2">
            <icon-arrow-link class="size-4 text-white" />
            <div class="text-white text-base font-semibold">{{ item.publisher?.name }}</div>
          </div>
        </div>
        <div class="flex flex-col items-center gap-y-1 text-nowrap">
          <div class="text-sm text-[#A7A7A7] font-medium">Developer</div>
          <div class="flex items-center gap-x-2">
            <icon-arrow-link class="size-4 text-white" />
            <div class="text-white text-base font-semibold">{{ item.developer?.name }}</div>
          </div>
        </div>
        <div class="flex flex-col items-center gap-y-1 text-nowrap">
          <div class="text-sm text-[#A7A7A7] font-medium">Category</div>
          <div class="flex items-center gap-x-2">
            <icon-arrow-link class="size-4 text-white" />
            <div class="text-white text-base font-semibold">{{ item.category?.name }}</div>
          </div>
        </div>
      </div>

      <image-box
        images-list-class="flex overflow-x-auto no-scrollbar scroll-smooth flex-nowrap gap-x-4 snap-x my-10 [&>div]:h-[315px] [&>div]:aspect-[4/3] [&>div]:snap-start [&>div>img]:size-full [&>div>img]:object-cover"
        :images="item.imageUrls"
      />

      <div class="text-white text-sm font-medium my-10">
        <div class="space-y-4">
          <p v-for="(paragraph, key) in paragraphs" :key="key">
            {{ paragraph }}
          </p>
        </div>
        <div v-if="false">
          <div>...</div>
          <button class="text-sm text-[#0074E4]">Show more</button>
        </div>
      </div>
    </template>

    <programs-header v-model="licenseType" class="mt-20 mb-6">
      <div class="shrink-0 text-white text-2xl font-semibold">
        Similar programs
      </div>
    </programs-header>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10">
      <program-card v-for="(program, key) in programs" :key="key" :item="program" class="cursor-pointer" @click="router.push(`/programs/${program.id}`)" />
    </div>

    <program-eternal-loader v-model:is-initial="isInitial" :load="load" />
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch } from 'vue';
import ButtonBack from '../molecules/ButtonBack.vue';
import ButtonRound from '../atoms/ButtonRound.vue';
import ButtonLaunch from '../atoms/ButtonLaunch.vue';
import ProgramsHeader from '../organisms/ProgramsHeader.vue';
import ProgramCard from '../organisms/ProgramCard.vue';
import ProgramEternalLoader from '../organisms/ProgramEternalLoader.vue';
import ImageBox from '../molecules/ImageBox.vue';
import DropdownDefault from '../organisms/DropdownDefault.vue';
import IconDotsVertical from '../icons/IconDotsVertical.vue';
import IconArrowLink from '../icons/IconArrowLink.vue';
import { useRouter } from 'vue-router';
import { useFetch } from '@vueuse/core';
import { apiDomain, licenseTypes } from '../composables/useConstants';
import { getUser } from '../composables/useAuth';

const props = defineProps({
  programId: {
    type: String,
    required: true
  }
});

const PER_PAGE = 6;

const router = useRouter();

const { data: item } = useFetch(
  `${apiDomain}/api/program/${props.programId}`,
  {
    onFetchError: ctx => {
      if (ctx.response.ok) {
        return;
      }

      router.push({ name: 'NotFound' });
    }
  }
).get().json();

const paragraphs = computed(() => item.value?.info?.split('\n') || []);

const programs = ref([]);
const page = ref(0);
const licenseType = ref(licenseTypes[0]);
const isInitial = ref(true);

watch(licenseType, reset);

const url = computed(() => {
  const data = {
    limit: PER_PAGE,
    offset: page.value * PER_PAGE,
    programId: props.programId
  };

  if (licenseType.value.value !== 'all') {
    data.licenseType = licenseType.value.value;
  }

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/program/?${searchParams}`;
});

function reset() {
  page.value = 0;
  programs.value = [];
  isInitial.value = true;
}

async function load({ loaded }) {
  const { data } = await useFetch(url.value).get().json();

  page.value++;
  programs.value.push(...data.value);
  loaded(data.value.length, data.value.length);
}

const user = getUser();

const contextMenuList = reactive([
  { name: 'Add to my programs' },
  { name: 'Show similar program' }
]);
</script>
