<template>
  <div class="fixed inset-0 bg-[#161616] flex items-center justify-center z-50">
    <icon-loader ref="svgLoader">
      <div class="text-center mt-1">Loading {{ loadingValue }}%</div>
    </icon-loader>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import IconLoader from '../icons/IconLoader.vue';

const props = defineProps({
  duration: {
    type: Number,
    default: 0
  }
});

const loadingValue = ref(0);

onMounted(() => {
  const interval = 100;
  const step = 100 / (props.duration / interval);

  const intervalId = setInterval(() => {
    if (loadingValue.value < 100) {
      loadingValue.value = Math.min(Math.round(loadingValue.value + step), 100);
      document.body.style.overflow = 'hidden';
    } else {
      clearInterval(intervalId);
      document.body.style.overflow = 'auto';
    }
  }, interval);
});
</script>
