<template>
  <canvas :id="canvasId" ref="canvasRef" />
</template>

<script setup>
import { Application } from '@splinetool/runtime';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

// Props with validation and defaults
const props = defineProps({
  canvasId: {
    type: String,
    default: 'spline-canvas'
  },
  scene: {
    type: Object,
    required: true
  },
  isReadyToShow: {
    type: Boolean,
    default: false
  }
});

const canvasRef = ref(null);
const splineInstance = ref(null);
const promiseVideo = ref(null);

const emit = defineEmits([ 'loaded', 'error' ]);

watch(() => props.isReadyToShow, newValue => {
  if (!newValue && promiseVideo.value) {
    promiseVideo.value
      .then(() => emit('loaded'))
      .catch(error => emit('error', error));
  }
});

onMounted(() => {
  if (canvasRef.value) {
    splineInstance.value = new Application(canvasRef.value);
    promiseVideo.value = splineInstance.value.load(props.scene.url, props.scene.variables);
  }
});

onBeforeUnmount(() => {
  splineInstance.value?.stop();
});

// Methods to control the Spline application
const play = () => splineInstance.value?.play();
const pause = () => splineInstance.value?.stop();
const setVariables = variables => splineInstance.value?.setVariables(variables);
const setVariable = (name, value) => splineInstance.value?.setVariable(name, value);
const getVariables = () => splineInstance.value?.getVariables();
const getVariable = name => splineInstance.value?.getVariable(name);
const stop = () => splineInstance.value?.stop();
const setBackgroundColor = color => splineInstance.value?.setBackgroundColor(color);
const getAllObjects = () => splineInstance.value?.getAllObjects();
const getSplineEvents = () => splineInstance.value?.getSplineEvents();
const setZoom = zoom => splineInstance.value?.setZoom(zoom);
const setSize = (width, height) => splineInstance.value?.setSize(width, height);
const findObjectById = uuid => splineInstance.value?.findObjectById(uuid);
const findObjectByName = name => splineInstance.value?.findObjectByName(name);
const emitEvent = (eventName, nameOrUuid) => splineInstance.value?.emitEvent(eventName, nameOrUuid);
const emitEventReverse = (eventName, nameOrUuid) => splineInstance.value?.emitEventReverse(eventName, nameOrUuid);
const addEventListener = (eventName, cb) => splineInstance.value?.addEventListener(eventName, cb);
const removeEventListener = (eventName, cb) => splineInstance.value?.removeEventListener(eventName, cb);
const spline = splineInstance;

defineExpose({
  spline,
  play,
  pause,
  stop,
  setVariables,
  setVariable,
  getVariables,
  getVariable,
  setBackgroundColor,
  getAllObjects,
  getSplineEvents,
  setZoom,
  setSize,
  findObjectById,
  findObjectByName,
  emitEvent,
  emitEventReverse,
  addEventListener,
  removeEventListener
});
</script>
