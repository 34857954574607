<template>
  <div
    class=" bg-white
  xs:px-[20px] xs:py-[20px] sm:px-[24px] sm:py-[24px] md:px-[50px] md:py-[60px] lg:px-[52px] lg:py-[59px] xl:px-[70px] xl:py-[79px] 2xl:px-[74px] 2xl:py-[70px] 3xl:px-[96px] 3xl:py-[111px] 4xl:px-[125px] 4xl:py-[144px]
  xs:rounded-[25px] sm:rounded-[25px] md:rounded-[25px] lg:rounded-[25px] xl:rounded-[34px] 2xl:rounded-[36px] 3xl:rounded-[48px] 4xl:rounded-[64px]"
  >
    <div class="flex flex-col lg:flex-row items-center xs:h-[auto] sm:h-[auto] md:h-[auto] lg:h-[323px] xl:h-[432px] 2xl:h-[456px] 3xl:h-[607px] 4xl:h-[607px]">
      <div class="sm:w-full md:w-full p-6 xs:px-0 lg:pl-6">
        <h2 class="font-semibold text-[#1A1A1A] xs:text-[25px] sm:text-[30px] md:text-[30px] lg:text-[30px] xl:text-[39px] 2xl:text-[42px] 3xl:text-[56px] 4xl:text-[74px]">
          Frequently asked questions
        </h2>
        <p class="mt-2 font-medium text-[#585858] xs:text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] xl:text-[17px] 2xl:text-[24px] 3xl:text-[24px] 4xl:text-[32px]">
          All you want to know about the product.
        </p>
      </div>
      <div class="sm:w-full md:w-full flex flex-col h-[inherit] justify-around">
        <div v-for="(item, index) in faqs" :key="index" class="border-gray-200" :class="{'border-b': index !== faqs.length - 1}">
          <button
            class="flex justify-between items-center w-full py-4 text-left focus:outline-none"
            @click="toggle(index)"
          >
            <span class="text-[#1A1A1A] font-semibold xs:text-[14px] sm:text-[17px] md:text-[17px] lg:text-[17px] xl:text-[22px] 2xl:text-[24px] 3xl:text-[32px] 4xl:text-[42px]">
              {{ item.question }}
            </span>
            <svg :class="{ 'transform rotate-180': item.open }" class="w-5 h-5 transition-transform duration-200" fill="none" stroke="#6A6A6A" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          <transition name="faq">
            <div v-show="item.open" class="pl-4 pr-2 pb-4 text-gray-600 overflow-hidden">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const faqs = ref([
  { question: "How can I be certain that my data is secure?", answer: "Your data is secured with the highest standards.", open: false },
  { question: "Do I have to install Aristeem?", answer: "No, you can access Aristeem directly from your browser.", open: false },
  { question: "Can I use Aristeem to store my files?", answer: "Yes, Aristeem allows you to securely store and access your files.", open: false },
  { question: "What if I can't find the interested program?", answer: "You can suggest a program to be added to our catalog.", open: false },
  { question: "How many programs can I use at the same time?", answer: "You can use multiple programs simultaneously depending on your plan.", open: false }
]);

const toggle = index => {
  faqs.value.forEach((faq, i) => {
    faq.open = i === index ? !faq.open : false;
  });
};
</script>

<style scoped>
.faq-enter-active, .faq-leave-active {
  transition: all 0.3s ease;
}

.faq-enter-from, .faq-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
}

.faq-enter-to, .faq-leave-from {
  max-height: 300px;
  opacity: 1;
  transform: translateY(0);
}
</style>
