<template>
  <div
    class="flex items-center justify-between px-4 py-3 sm:px-6 sm:py-[18px] rounded-3xl"
    :class="{ 'bg-[#272727]': selected }"
  >
    <checkbox-default v-model="model" :label="item.name" class="font-semibold truncate" />
    <div class="text-xs sm:text-sm font-medium text-[#A7A7A7] text-right">{{ format(item.total) }}</div>
  </div>
</template>

<script setup>
import CheckboxDefault from '../atoms/CheckboxDefault.vue';

defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
});

const model = defineModel('selected', { type: Boolean, default: false }, 'change');

function format(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
</script>
