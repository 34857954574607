import { createRouter, createWebHistory } from 'vue-router';
import { signOut, doesSessionExist } from '../composables/useAuth.js';

import MainPage from '../pages/MainPage.vue';
import StartPage from '../pages/StartPage.vue';
import LoggedUserPage from '../pages/LoggedUserPage.vue';
import LibraryPage from '../pages/LibraryPage.vue';
import ProgramPage from '../pages/ProgramPage.vue';
import ProgramsPage from '../pages/ProgramsPage.vue';
import FilesPage from '../pages/FilesPage.vue';
import LoginPage from '../pages/LoginPage.vue';
import TermsPage from '../pages/TermsPage.vue';
import FeaturesPage from '../pages/FeaturesPage.vue';
import PricingPage from '../pages/PricingPage.vue';
import CompanyPage from '../pages/CompanyPage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';

let authorizeResult = null;

async function isAuthorized() {
  if (authorizeResult === null) {
    const _isAuthorized = await doesSessionExist();
    authorizeResult = { isAuthorized: _isAuthorized };
  }

  return authorizeResult.isAuthorized;
}

const routes = [
  {
    path: '/programs/',
    name: 'Programs',
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivatePrograms' });
      } else {
        next({ name: 'PublicPrograms' });
      }
    }
  },
  {
    path: '/programs/:programId',
    name: 'Program',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateProgram', params: _to.params });
      } else {
        next({ name: 'PublicProgram', params: _to.params });
      }
    }
  },

  {
    path: '/',
    component: MainPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        component: StartPage
      },
      {
        path: '/features',
        component: FeaturesPage
      },
      {
        path: '/pricing',
        component: PricingPage
      },
      {
        path: '/company',
        component: CompanyPage
      },
      {
        path: '/programs',
        name: 'PublicPrograms',
        component: LibraryPage,
        props: { isPublic: true }
      },
      {
        path: '/programs/:programId',
        name: 'PublicProgram',
        component: ProgramPage,
        props: true
      }
    ]
  },

  {
    path: '/user',
    component: LoggedUserPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        redirect: '/library'
      },
      {
        path: '/library',
        component: LibraryPage
      },
      {
        path: '/programs',
        name: 'PrivatePrograms',
        component: ProgramsPage
      },
      {
        path: '/programs/:programId',
        name: 'PrivateProgram',
        component: ProgramPage,
        props: true
      },
      {
        path: '/files',
        component: FilesPage
      }
    ]
  },
  {
    path: '/login',
    component: LoginPage,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    }
  },
  {
    path: '/welcome',
    component: LoginPage,
    props: { isWelcome: true },
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    }
  },
  {
    path: '/logout',
    component: true, // NOTE: this is a hack to make the beforeEnter hook work
    beforeEnter: async () => {
      await signOut();
      router.push('/');
    }
  },
  {
    path: '/terms',
    component: TermsPage
  },

  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.querySelector('main');
    if (element) {
      element.scrollTo(0, 0);
      return null;
    }

    return { top: 0 };
  }
});

router.afterEach(() => {
  // reset the authorization check
  authorizeResult = null;
});

export default router;
