<template>
  <label class="inline-flex items-center space-x-2">
    <div
      class="size-5 rounded border flex items-center justify-center cursor-pointer text-[0px] transition-colors"
      :class="model ? 'bg-white border-white' : 'bg-transparent border-[#A7A7A7]'"
    >
      <icon-check
        class="size-4 text-[#272727] transition-opacity"
        :class="{ 'opacity-0': !model }"
      />
    </div>
    <div class="truncate">{{ label }}</div>
    <input v-model="model" type="checkbox" class="hidden">
  </label>
</template>

<script setup>
import IconCheck from '../icons/IconCheck.vue';

defineProps({
  label: {
    type: String,
    default: null
  }
});

const model = defineModel({ type: Boolean, default: false });
</script>
