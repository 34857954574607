<template>
  <div
    ref="animatedCardsRef"
    class="bg-[#ffffff] transition-all duration-500 relative p-10"
  >
    <div class="xs:p-1 md:p-16 relative">
      <div class="bg-[#f4f5f7] absolute z-0 transition-all duration-500" :class="isReachingToOutOfView ? '-inset-10' : 'inset-0 rounded-2xl'" />
      <header class="text-[#1A1A1A] relative text-center font-semibold sm:text-[35px] md:text-[35px] lg:text-[35px] xl:text-[47px] 2xl:text-[50px] 3xl:text-[66px] 4xl:text-[88px]">
        <div class="flex justify-center items-center">
          <icon-features
            :size="is4Xl ? '23px' : is3Xl ? '17px' : is2Xl || isXl ? '13px' : '10px'"
          />
          <span class="text-[#0074E4] ml-1 font-extrabold xs:text-[12px] sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[12px] 3xl:text-[16px] 4xl:text-[21px]">FEATURES</span>
        </div>
        <div>All must-have</div>
        <div>programs in 1 place</div>
      </header>
      <div class="relative w-full">
        <cards-wrapper :cards="cardItems" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import IconFeatures from '../icons/IconFeatures.vue';
import { useMediaQuery, useIntersectionObserver } from '@vueuse/core';
import CardsWrapper from '../molecules/CardsWrapper.vue';

import video1Url from '../assets/videos/illustration_1.mp4';
import video2Url from '../assets/videos/illustration_2.mp4';
import video3Url from '../assets/videos/illustration_3.mp4';

const animatedCardsRef = ref(null);
const isReachingToOutOfView = ref(false);

useIntersectionObserver(
  animatedCardsRef,
  ([ entry ]) => {
    isReachingToOutOfView.value = entry.isIntersecting;
  },
  {
    threshold: 0.3
  }
);

const isXl = useMediaQuery('(min-width: 1280px)');
const is2Xl = useMediaQuery('(min-width: 1536px)');
const is3Xl = useMediaQuery('(min-width: 1920px)');
const is4Xl = useMediaQuery('(min-width: 2560px)');

const cardItems = [
  {
    position: 1,
    title: 'Accessible',
    description: `Anywhere, you can complete your toughest project with a regular device and fast data processing.`,
    videoUrl: video1Url
  },
  {
    position: 2,
    title: 'Profitable',
    description: `You don't need to buy expensive computers and workstations for video processing, modeling, and rendering.`,
    videoUrl: video2Url
  },
  {
    position: 3,
    title: 'Securely',
    description: `You will be able to safely store huge amounts of data and get access to your work outcomes.`,
    videoUrl: video3Url
  }
];

</script>
