<template>
  <template v-if="programs">
    <div class="2xl:container 2xl:mx-auto">
      <program-carousel :slides="programSlides" class="mb-6 lg:mb-16 relative z-10 lg:z-0" />

      <h2 class="text-white text-[32px] leading-[42px] font-semibold">Programs</h2>

      <programs-header v-model="licenseType" class="mt-5">
        <button-default class="space-x-2 h-[52px] px-6" @click="emit('openSearchForm')">
          <icon-filter class="size-4" />
          <div class="text-sm font-semibold leading-none">Filters</div>
        </button-default>

        <program-filter v-model="selected" />
      </programs-header>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10 mt-6">
        <program-card v-for="(program, key) in programs" :key="key" :item="program" class="cursor-pointer" @click="router.push(`/programs/${program.id}`)" />
      </div>

      <program-eternal-loader v-model:is-initial="isInitial" :load="load" />
    </div>
  </template>
  <template v-else>
    <empty-state title="Programs list is empty" sub-title="Add your first program from library">
      <template #icon>
        <img src="../assets/images/IconPrograms.png" class="size-[54px]" alt="Empty files">
      </template>
      <template #button>
        <button-default class="px-6">Add program</button-default>
      </template>
    </empty-state>
  </template>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import ProgramCarousel from '../organisms/ProgramCarousel.vue';
import { apiDomain, programSlides, licenseTypes } from '../composables/useConstants';
import { useFetch } from '@vueuse/core';
import { useRouter } from 'vue-router';
import EmptyState from '../atoms/EmptyState.vue';
import ProgramsHeader from '../organisms/ProgramsHeader.vue';
import ProgramEternalLoader from '../organisms/ProgramEternalLoader.vue';
import ProgramFilter from '../organisms/ProgramFilter.vue';
import ProgramCard from '../organisms/ProgramCard.vue';
import IconFilter from '../icons/IconFilter.vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';

const selected = defineModel('selected', { type: Object, default: {} });

const emit = defineEmits([ 'openSearchForm' ]);

const PER_PAGE = 6; // FIXME: pick up proper value, may be based on screen size

const router = useRouter();
const page = ref(0);
const programs = ref([]);
const licenseType = ref(licenseTypes[0]);
const isInitial = ref(true);

watch(licenseType, reset);
watch(selected, reset);

const url = computed(() => {
  const data = {
    limit: PER_PAGE,
    offset: page.value * PER_PAGE
  };

  if (licenseType.value.value !== 'all') {
    data.licenseType = licenseType.value.value;
  }

  if (selected.value?.ids?.length && selected.value?.section === 'categories') {
    data.categoryIds = selected.value.ids;
  }

  if (selected.value?.ids?.length && selected.value?.section === 'developers') {
    data.developerIds = selected.value.ids;
  }

  if (selected.value?.ids?.length && selected.value?.section === 'publishers') {
    data.publisherIds = selected.value.ids;
  }

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/program/?${searchParams}`;
});

function reset() {
  page.value = 0;
  programs.value = [];
  isInitial.value = true;
}

async function load({ loaded }) {
  const { data } = await useFetch(url.value).get().json();

  page.value++;
  programs.value.push(...data.value);
  loaded(data.value.length, data.value.length);
}
</script>
