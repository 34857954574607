<template>
  <div ref="animatedFooterRef" class="bg-white relative h-[125vh]">
    <div
      class="relative z-[2] bg-[#f4f5f7] xs:px-[20px] xs:py-[20px] sm:px-[24px] sm:py-[24px] md:px-[50px] md:py-[60px] lg:px-[52px] lg:py-[59px] xl:px-[70px] xl:py-[79px] 2xl:px-[74px] 2xl:py-[70px] 3xl:px-[96px] 3xl:py-[111px] 4xl:px-[125px] 4xl:py-[144px]
        flex items-center justify-center -mt-px flex-col shadow-2xl xs:rounded-b-[25px] sm:rounded-b-[25px] md:rounded-b-[25px] lg:rounded-b-[25px] xl:rounded-b-[34px] 2xl:rounded-b-[36px] 3xl:rounded-b-[48px] 4xl:rounded-b-[64px]"
    >
      <faq class="xs:w-[380px] sm:w-[380px] md:w-[668px] lg:w-[919px] xl:w-[1225px] 2xl:w-[1292px] 3xl:w-[1726px] 4xl:w-[1726px]" />
    </div>

    <div
      :class="[isReachingToOutOfView ? 'block' : 'hidden']"
      class="fixed bottom-5 z-[1] left-[50%] translate-x-[-50%] xs:w-[380px] sm:w-[380px] md:w-[668px] lg:w-[919px] xl:w-[1225px] 2xl:w-[1292px] 3xl:w-[1726px] 4xl:w-[1726px] flex justify-between mx-auto pb-10"
    >
      <icon-aristeem-mini :size="is4Xl ? '72px' : is3Xl ? '54px' : is2Xl ? '40px' : isXl ? '18px' : isLG ? '28px' : isMD ? '14px' : '17px'" />
      <div class="font-medium text-[#585858] xs:text-[10px] sm:text-[12px] md:text-[14px] lg:text-[12px] xl:text-[15px] 2xl:text-[16px] 3xl:text-[21px] 4xl:text-[28px]">© 2024 Aristeem, Inc. All rights reserved.</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Faq from '../molecules/Faq.vue';
import IconAristeemMini from '../icons/IconAristeemMini.vue';
import { useMediaQuery, useIntersectionObserver } from '@vueuse/core';

const isMD = useMediaQuery('(min-width: 768px)');
const isLG = useMediaQuery('(min-width: 1024px)');
const isXl = useMediaQuery('(min-width: 1280px)');
const is2Xl = useMediaQuery('(min-width: 1536px)');
const is3Xl = useMediaQuery('(min-width: 1920px)');
const is4Xl = useMediaQuery('(min-width: 2560px)');

const animatedFooterRef = ref(null);
const isReachingToOutOfView = ref(false);

useIntersectionObserver(
  animatedFooterRef,
  ([ entry ]) => {
    isReachingToOutOfView.value = entry.isIntersecting;
  },
  {
    threshold: 0.3
  }
);
</script>
