<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="12" r="1" transform="rotate(90 8 12)" fill="currentColor" />
    <circle cx="8" cy="8" r="1" transform="rotate(90 8 8)" fill="currentColor" />
    <circle cx="8" cy="4" r="1" transform="rotate(90 8 4)" fill="currentColor" />
  </svg>
</template>

<script setup>

</script>
