<template>
  <layout-centered>
    <template #default>
      <form-login :state="state" @submit="submit" />

      <div class="absolute top-5 right-5">
        <router-link v-slot="{ navigate }" to="/" custom>
          <button-round @click="navigate">
            <icon-close class="size-10" />
          </button-round>
        </router-link>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center">
        <div class="text-[#747474] text-sm pt-9 pb-10 border-t border-[#1B1B1B] text-center text-balance">
          By signing up, you agree to our
          <router-link to="/terms" class="link ml-1">
            Terms of Service
          </router-link>
        </div>
      </div>
    </template>
  </layout-centered>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import LayoutCentered from '../templates/LayoutCentered.vue';
import FormLogin from '../organisms/FormLogin.vue';
import ButtonRound from '../atoms/ButtonRound.vue';
import IconClose from '../icons/IconClose.vue';
import {
  doesEmailExist,
  signIn
} from '../composables/useAuth';
import {
  STATE_LOGIN_INITIAL,
  STATE_LOGIN_WELCOME,
  STATE_LOGIN_EMAIL_CHECKING,
  STATE_LOGIN_EMAIL_SUCCESS,
  STATE_LOGIN_EMAIL_FAIL,
  STATE_LOGIN_EMAIL_RESEND,
  STATE_LOGIN_PASSWORD_ASK,
  STATE_LOGIN_PASSWORD_CHECKING,
  STATE_LOGIN_PASSWORD_SUCCESS,
  STATE_LOGIN_PASSWORD_FAIL
} from '../composables/useConstants';

const props = defineProps({
  isWelcome: {
    type: Boolean,
    default: false
  }
});

const initialState = props.isWelcome ? STATE_LOGIN_WELCOME : STATE_LOGIN_INITIAL;
const state = ref(initialState);
const router = useRouter();

function submit({ state: _state, email, password }) {
  if ((_state === STATE_LOGIN_INITIAL || _state === STATE_LOGIN_WELCOME || _state === STATE_LOGIN_EMAIL_FAIL) && email) {
    // NOTE: we must possibly separate welcome and login in future
    checkEmail(email);
    return;
  }

  if (_state === STATE_LOGIN_PASSWORD_ASK && email && password) {
    checkPassword(email, password);
    return;
  }

  if (_state === STATE_LOGIN_EMAIL_RESEND && email) {
    resendEmail(email);
    return;
  }

  console.log('Invalid state');
}

async function checkEmail(email) {
  state.value = STATE_LOGIN_EMAIL_CHECKING;

  const response = await doesEmailExist({ email });

  if (response.success) {
    // FIXME: for now we support only existing users
    // state.value = response.doesExist ? STATE_LOGIN_PASSWORD_ASK : STATE_LOGIN_EMAIL_SUCCESS;
    state.value = response.doesExist ? STATE_LOGIN_PASSWORD_ASK : STATE_LOGIN_EMAIL_FAIL;
    return;
  }

  // FIXME: show error message?
  state.value = STATE_LOGIN_EMAIL_FAIL;
  console.log('Error checking email:', response.error);
}

async function checkPassword(email, password) {
  state.value = STATE_LOGIN_PASSWORD_CHECKING;

  const response = await signIn(email, password);

  if (response.success) {
    state.value = STATE_LOGIN_PASSWORD_SUCCESS;
    router.push('/');
    return;
  }

  if (response.error === 'WRONG_CREDENTIALS_ERROR') {
    state.value = STATE_LOGIN_PASSWORD_FAIL;
    return;
  }

  // NOTE: possible check for 'FIELD_ERROR' status if email is invalid format
  state.value = STATE_LOGIN_PASSWORD_FAIL;
}

function resendEmail(email) {
  // FIXME: implement resend email
  console.log('Resending email...', email);
  state.value = STATE_LOGIN_EMAIL_RESEND;
  setTimeout(() => {
    state.value = STATE_LOGIN_EMAIL_SUCCESS;
  }, 2000);
}
</script>
