<template>
  <div
    id="animated-text-section"
    ref="animatedTextRef"
    class="bg-[#f4f5f7] relative p-10 h-[100vh]"
  >
    <div class="p-16 relative h-full">
      <div class="bg-[#ffff] absolute z-0 transition-all duration-500" :class="isReachingToOutOfView ? '-inset-10' : 'inset-0 rounded-2xl'" />
      <div class="relative flex justify-center items-center h-[inherit]">
        <header class="text-[#1a1a1a4d] font-semibold text-left sm:leading-[50.1px] md:leading-[55.64px] lg:leading-[55.64px] xl:leading-[74.9px] 2xl:leading-[79.18px] 3xl:leading-[104.86px] 4xl:leading-[140.17px] xs:text-[15px] sm:text-[30px] md:text-[42px] lg:text-[52px] xl:text-[70px] 2xl:text-[74px] 3xl:text-[98px] 4xl:text-[131px]">
          <div id="dynamicHeadWrapper2">
            <div id="description">
              <template v-for="word in dynamicWords.split('')" :key="word">
                <br v-if="word === '\n'">
                <span v-if="word !== ''" class="char">{{ word }}</span>
                <span v-if="word === ''">&nbsp;</span>
              </template>
            </div>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { gsap } from 'gsap';

const dynamicWords = 'Video and music have already \n moved to streaming. Today, it\'s \n video games making the shift, and \n office programs are heading to the \n cloud right now.';
const animatedTextRef = ref(null);

const isReachingToOutOfView = ref(false);

useIntersectionObserver(
  animatedTextRef,
  ([ entry ]) => {
    isReachingToOutOfView.value = entry.isIntersecting;
  },
  {
    threshold: 0.6
  }
);

onMounted(() => {
  const chars = document.querySelectorAll('#animated-text-section .char');

  gsap.fromTo(
    chars,
    { color: '#1a1a1a4d' },
    {
      color: '#1B1B1B',
      duration: 1,
      stagger: {
        each: 1
      },
      ease: 'none',
      scrollTrigger: {
        trigger: animatedTextRef.value,
        start: 'top top',
        end: 'bottom center',
        scrub: 0.5,
        pin: true
      }
    }
  );
});
</script>
