<template>
  <div class="w-full h-[52px] flex justify-between items-center space-x-3">
    <div class="shrink-0 flex items-center">
      <slot />
    </div>

    <div class="grow flex justify-end">
      <div class="flex-col justify-center items-end gap-0.5 inline-flex">
        <div class="text-neutral-400 text-sm font-medium leading-tight">Showing</div>
        <div class="text-white text-base font-semibold leading-none">{{ count }} programs</div>
      </div>
      <div class="ml-3 h-8 border-r border-[#272727]" />
    </div>

    <div class="shrink-0">
      <dropdown-default v-model="model" :items="licenseTypes">
        <button-default class="space-x-2 h-[52px] w-24 px-6">
          <div class="text-sm font-semibold leading-none">{{ model.name }}</div>
          <icon-arrow-down class="size-4" />
        </button-default>
      </dropdown-default>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import DropdownDefault from './DropdownDefault.vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import IconArrowDown from '../icons/IconArrowDown.vue';
import { licenseTypes } from '../composables/useConstants';
import { getStats } from '../composables/useProgramStats';

const model = defineModel({ type: Object, default: () => ({}) });
const stats = getStats();

const count = computed(() => {
  if (!stats.value) {
    return 0;
  }

  if (model.value?.value === 'all') {
    return stats.value.total;
  }

  if (model.value?.value === 'free') {
    return stats.value.free;
  }

  if (model.value?.value === 'paid') {
    return stats.value.paid;
  }

  return 0;
});
</script>
