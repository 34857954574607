export async function useProgramLaunch(appId = 753, splashImageUrl = null) {

  const sessionId = generateUUID();

  localStorage.setItem('appId', appId);
  localStorage.setItem('splashImageUrl', splashImageUrl);
  localStorage.setItem('sessionId', sessionId);
  localStorage.setItem('homeLink', `"${window.location.href}"`);
  localStorage.setItem('gateway_pings', '[{"address":"https://testsg.cloudoffice.live","priority":false,"location":{"country":"Ukraine","city":"Kyiv","latitude":0,"longitude":0}}]');

  window.open('/streaming/streaming.html', '_blank');
}

function generateUUID() {
  // eslint-disable-next-line
  return ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}
