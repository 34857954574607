<template>
  <div class="2xl:container 2xl:mx-auto">
    <program-carousel :slides="programSlides" class="mb-6 lg:mb-16 relative z-10 lg:z-0" />

    <h2 class="text-white text-[32px] leading-[42px] font-semibold">Library</h2>

    <programs-header v-if="!isPublic" v-model="licenseType" class="mt-5">
      <button-default class="space-x-2 h-[52px] px-6" @click="emit('openSearchForm')">
        <icon-filter class="size-4" />
        <div class="text-sm font-semibold leading-none">Filters</div>
      </button-default>

      <program-filter v-model="selected" />
    </programs-header>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10 mt-6">
      <program-card v-for="(program, key) in programs" :key="key" :item="program" class="cursor-pointer" @click="router.push(`/programs/${program.id}`)" />
    </div>

    <program-eternal-loader v-model:is-initial="isInitial" :load="load" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import ProgramsHeader from '../organisms/ProgramsHeader.vue';
import ProgramCard from '../organisms/ProgramCard.vue';
import ProgramCarousel from '../organisms/ProgramCarousel.vue';
import ProgramEternalLoader from '../organisms/ProgramEternalLoader.vue';
import ProgramFilter from '../organisms/ProgramFilter.vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import IconFilter from '../icons/IconFilter.vue';
import { useFetch } from '@vueuse/core';
import { apiDomain, programSlides, licenseTypes } from '../composables/useConstants';
import { useRouter } from 'vue-router';

defineProps({
  isPublic: {
    type: Boolean,
    default: false
  }
});

const selected = defineModel('selected', { type: Object, default: {} });

const emit = defineEmits([ 'openSearchForm' ]);

const PER_PAGE = 6; // FIXME: pick up proper value, may be based on screen size

const router = useRouter();
const programs = ref([]);
const page = ref(0);
const licenseType = ref(licenseTypes[0]);
const isInitial = ref(true);

watch(licenseType, reset);
watch(selected, reset);

const url = computed(() => {
  const data = {
    limit: PER_PAGE,
    offset: page.value * PER_PAGE
  };

  if (licenseType.value.value !== 'all') {
    data.licenseType = licenseType.value.value;
  }

  if (selected.value?.ids?.length && selected.value?.section === 'categories') {
    data.categoryIds = selected.value.ids;
  }

  if (selected.value?.ids?.length && selected.value?.section === 'developers') {
    data.developerIds = selected.value.ids;
  }

  if (selected.value?.ids?.length && selected.value?.section === 'publishers') {
    data.publisherIds = selected.value.ids;
  }

  const searchParams = new URLSearchParams(data);

  return `${apiDomain}/api/program/?${searchParams}`;
});

function reset() {
  page.value = 0;
  programs.value = [];
  isInitial.value = true;
}

async function load({ loaded }) {
  const { data } = await useFetch(url.value).get().json();

  page.value++;
  programs.value.push(...data.value);
  loaded(data.value.length, data.value.length);
}
</script>
