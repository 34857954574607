<template>
  <div class="bg-[#272727] rounded-3xl p-0.5 cursor-pointer transition-[width,height]" :class="isSidebarExpanded ? 'w-full h-16' : 'w-16 h-[34px]'" @click="isDark = !isDark">
    <div class="w-full h-full flex relative">
      <div class="absolute inset-y-0 bg-[#121212] w-1/2 rounded-[23px] z-0 transition-[left]" :class="isDark ? 'left-0' : 'left-1/2'" />
      <div class="w-1/2 h-full flex justify-center items-center relative space-x-2" :class="isDark ? 'text-white' : 'text-[#A7A7A7] hover:text-white'">
        <icon-moon class="size-4" />
        <div v-if="isSidebarExpanded">Dark</div>
      </div>
      <div class="w-1/2 h-full flex justify-center items-center relative space-x-2" :class="!isDark ? 'text-white' : 'text-[#A7A7A7] hover:text-white'">
        <icon-sun class="size-4" />
        <div v-if="isSidebarExpanded">Light</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { shallowRef } from 'vue';
import IconSun from '../icons/IconSun.vue';
import IconMoon from '../icons/IconMoon.vue';

defineProps({
  isSidebarExpanded: {
    type: Boolean,
    default: false
  }
});

const isDark = shallowRef(false);
</script>
