<template>
  <div ref="laydoutDefaultRef" class="h-full">
    <div class="h-full transition-[padding] relative">
      <div class="relative z-0 h-[84px] lg:h-28 -top-1 custom-backdrop-blur" />

      <div class="h-[100vh] flex flex-col">
        <div class="main-container fixed w-full top-0 z-20 flex h-[84px] lg:h-28 shrink-0 items-center gap-x-4 sm:gap-x-6 transition-all duration-500" :class="{ 'top-[-110px]' : isHidden, 'bg-[#A1A1A133] backdrop-blur-[4px]': isOutOfViewLess }">
          <router-link to="/" class="ml-4 lg:ml-6" :class="[isOutOfView ? 'text-[#121212]' : 'text-white']">
            <company-logo
              class="sm:w-[24.5px] sm:h-[23.46px] lg:w-[103.36px] lg:h-[13.12px] xl:w-[144px] xl:h-[18.28px] 2xl:w-[144px] 2xl:h-[18.28px] 3xl:w-[193.76px] 3xl:h-[24.6px] 4xl:w-[258.45px] 4xl:h-[32.82px]"
              :is-full="!isMobile"
            />
          </router-link>

          <div class="flex flex-1 gap-x-2 self-stretch">
            <div class="w-full flex items-center gap-x-2 mr-4 lg:mr-6">
              <slot name="navbar" />
            </div>
          </div>
        </div>

        <main class="grow overflow-y-auto overscroll-none scroll-smooth -mt-28 lg:pt-28 px-4 lg:px-6 pb-10">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>

<script setup>
// NOTE:
// - breakpoint for mobile is 'lg'
// - sidebar widths for normal and expanded view are defined in tailwind.config.js with 'sidebar' and 'sidebar' key

import { ref, watch } from 'vue';
import CompanyLogo from '../atoms/CompanyLogo.vue';
import { isMobile } from '../composables/useBreakpoints';
import { useScroll, useIntersectionObserver } from '@vueuse/core';

const { y } = useScroll(document);
const lastScrollY = ref(0);
const isScrollingUp = ref(false);
const isHidden = ref(false);

watch(y, currentY => {
  isScrollingUp.value = Boolean(currentY < lastScrollY.value);
  isHidden.value = Boolean(currentY > 150 && !isScrollingUp.value);

  lastScrollY.value = currentY;
});

const isOutOfViewLess = ref(false);
const isOutOfView = ref(false);
const laydoutDefaultRef = ref(null);

useIntersectionObserver(
  laydoutDefaultRef,
  ([ entry ]) => {
    isOutOfViewLess.value = !entry.isIntersecting;
  },
  {
    threshold: 0.9
  }
);

useIntersectionObserver(
  laydoutDefaultRef,
  ([ entry ]) => {
    isOutOfView.value = !entry.isIntersecting;
  },
  {
    threshold: 0.1
  }
);

const emit = defineEmits([ 'getIsOutOfView' ]);

watch(isOutOfView, newValue => {
  emit('getIsOutOfView', newValue);
});
</script>
