<template>
  <div class="flex items-center gap-x-4">
    <user-avatar />

    <div class="flex flex-col">
      <div class="flex items-center space-x-2.5">
        <div class="text-base font-semibold text-white">
          Samuel El Jackson
        </div>
        <icon-arrow-right class="w-4 h-4 text-[#A7A7A7]" />
      </div>
      <div class="text-sm font-medium text-[#A7A7A7]">
        {{ user?.email }}
      </div>
    </div>
  </div>
</template>

<script setup>
import UserAvatar from '../molecules/UserAvatar.vue';
import IconArrowRight from '../icons/IconArrowRight.vue';
import { getUser } from '../composables/useAuth';

const user = getUser();
</script>
