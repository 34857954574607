export const apiDomain = import.meta.env.VITE_APP_API_URL;

export const STATE_LOGIN_INITIAL = 0;
export const STATE_LOGIN_WELCOME = 1;
export const STATE_LOGIN_EMAIL_CHECKING = 2;
export const STATE_LOGIN_EMAIL_SUCCESS = 3;
export const STATE_LOGIN_EMAIL_FAIL = 4;
export const STATE_LOGIN_EMAIL_RESEND = 5;
export const STATE_LOGIN_PASSWORD_ASK = 6;
export const STATE_LOGIN_PASSWORD_CHECKING = 7;
export const STATE_LOGIN_PASSWORD_SUCCESS = 8;
export const STATE_LOGIN_PASSWORD_FAIL = 9;

export const programSlides = [
  {
    title: 'Selected for designers',
    description: 'Photoshop, Illustrator, After Effects and other programs that perfectly suits for designers ',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/images/public/designer.mp4'
  },
  {
    title: 'Selected for 3d artists',
    description: 'Cinema 4D, Blender, and other programs that perfectly suits for 3d artists',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/images/public/3dArtist.mp4'
  },
  {
    title: 'Selected for video makers',
    description: 'Premiere Pro, After Effects and other programs that perfectly suits for video makers',
    videoUrl: 'https://d9usr6i0h5fem.cloudfront.net/images/public/videoMaker.mp4'
  }
];

export const licenseTypes = [
  { name: 'All', value: 'all' },
  { name: 'Free', value: 'free' },
  { name: 'Paid', value: 'paid' }
];
