<template>
  <div v-if="name" class="hidden sm:flex items-center">
    <div class="mx-3 h-8 border-r border-[#272727]" />
    <button-default class="space-x-2 h-[52px] !bg-white" @click="model = []">
      <span class="text-[#272727] text-sm font-semibold">{{ name }}</span>

      <svg xmlns="http://www.w3.org/2000/svg" class="size-4" viewBox="0 0 16 16" fill="none">
        <path d="M1.66699 8.00002C1.66699 5.01446 1.66699 3.52168 2.59449 2.59418C3.52199 1.66669 5.01477 1.66669 8.00033 1.66669C10.9859 1.66669 12.4787 1.66669 13.4062 2.59418C14.3337 3.52168 14.3337 5.01446 14.3337 8.00002C14.3337 10.9856 14.3337 12.4784 13.4062 13.4059C12.4787 14.3334 10.9859 14.3334 8.00033 14.3334C5.01477 14.3334 3.52199 14.3334 2.59449 13.4059C1.66699 12.4784 1.66699 10.9856 1.66699 8.00002Z" fill="#272727" />
        <path d="M10 6L6 9.99973M10 10L6 6.00026" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button-default>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import ButtonDefault from '../atoms/ButtonDefault.vue';
import { getStats } from '../composables/useProgramStats';

const model = defineModel({ type: Object, default: () => ({}) });
const stats = getStats();

const name = computed(() => {
  if (!model.value?.ids?.length || !model.value?.section) {
    return null;
  }

  const _name = stats.value?.[model.value.section].find(c => c.id === model.value.ids[0])?.name || null;
  if (model.value.ids.length === 1) {
    return _name;
  }

  return `${_name} + ${model.value.ids.length - 1} more`;
});
</script>
