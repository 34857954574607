<template>
  <Menu as="div" class="relative">
    <MenuButton class="flex items-center">
      <slot />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems v-if="items.length" :class="[ menuPlacementLeft ? 'left-0' : 'right-0' ]" class="absolute z-10 mt-2.5 origin-top-right rounded-3xl bg-[#272727] py-4 shadow-lg ring-1 ring-white/10 focus:outline-none">
        <MenuItem v-for="(item, key) in items" :key="key" v-slot="{ active }">
          <div
            class="flex gap-x-2 items-center mx-3 my-1 pl-2 py-1 text-base font-semibold leading-6 text-[#A7A7A7] text-nowrap rounded-lg cursor-pointer"
            :class="[
              active ? 'bg-white' : null,
              isSelected(item) ? 'pointer-events-none text-white' : 'text-[#A7A7A7]'
            ]"
            @click="model = item"
          >
            <div v-if="withIcons" class="shrink-0">
              <component :is="item.icon || 'div'" class="size-5" />
            </div>
            <div class="flex-1 mr-4">
              {{ item.name }}
            </div>
            <div class="shrink-0 w-5 flex items-center">
              <icon-check v-if="isSelected(item)" class="size-4" />
            </div>
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed } from 'vue';
import IconCheck from '../icons/IconCheck.vue';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  menuPlacementLeft: {
    type: Boolean,
    default: false
  }
});

const model = defineModel({ type: Object, default: null });
const withIcons = computed(() => props.items.some(item => item.icon));

function isSelected(item) {
  return model.value?.value !== undefined && model.value?.value === item?.value;
}
</script>
